import React, { useState, Component } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const Employee201 = () => {
  const [modal_center, setmodal_center] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [drp_secondary, setDrp_secondary] = useState(false)

  document.title = "Employee | Disciplinary"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Disciplinary"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>View Disciplinary</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <FilterTxt
                        name="search"
                        type="text"
                        className="form-control w-100 h-100"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={5}>
                      <div className="btn-group">
                        <ButtonDropdown
                          isOpen={drp_secondary}
                          toggle={() => setDrp_secondary(!drp_secondary)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-outline-info"
                          >
                            <i
                              className="typcn typcn-th-list"
                              style={{ fontSize: "18px" }}
                            />{" "}
                            Select Status <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Terminated</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Warning</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={tog_center}
                          size="md"
                          color="primary"
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="typcn typcn-folder-add"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                          Create Disciplinary
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee Name</th>
                          <th scope="col">Department</th>
                          <th scope="col">Disciplinary Type</th>
                          <th scope="col">Reason</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alil Rodriguez
                            </div>
                          </td>
                          <td>Back End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>Front End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "80px" }}
                            >
                              Warning
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Brent
                            </div>
                          </td>
                          <td>Project Management</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alil Rodriguez
                            </div>
                          </td>
                          <td>Back End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>Front End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "80px" }}
                            >
                              Warning
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Brent
                            </div>
                          </td>
                          <td>Project Management</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alil Rodriguez
                            </div>
                          </td>
                          <td>Back End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>Front End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "80px" }}
                            >
                              Warning
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Brent
                            </div>
                          </td>
                          <td>Project Management</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alil Rodriguez
                            </div>
                          </td>
                          <td>Back End</td>
                          <td>Misconduct</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "80px" }}
                            >
                              Terminated
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                {/* Create Discip Modal */}
                <Modal
                  isOpen={modal_center}
                  toggle={tog_center}
                  centered
                  size="lg"
                >
                  <ModalHeader className="mt-0" toggle={tog_center}>
                    Create Disciplinary
                  </ModalHeader>
                  <ModalBody className="m-2">
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Employee Name
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>John Doe</option>
                          <option>Alil Aljon</option>
                          <option>Jay Brent</option>
                          <option>Brent Bloss</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">
                        Disciplinary type
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>Misconduct</option>
                          <option>Verbal Warning</option>
                          <option>Suspension</option>
                          <option>Termination</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Reason</label>
                      <div className="col-md-10">
                        <textarea
                          id="txtAddress2Billing"
                          name="txtAddress2Billing"
                          rows="4"
                          className="form-control"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Status</label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>Warning</option>
                          <option>Terminated</option>
                        </select>
                      </div>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" onClick={tog_center}>
                      Save changes
                    </Button>
                    <Button color="secondary" onClick={tog_center}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Employee201
