import React, { useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  InputGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavItem,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import Select from "react-select"
import { Link, NavLink } from "react-router-dom"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { selectEmployee } from "constants/data"
import styled from "styled-components"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"

const SearchView = styled.div`
  .filter-item {
    width: 250px;
    margin-right: 10px;

    .InputView {
      height: 37px;
    }
  }
`

const optionGroup = [
  {
    label: "Employee",
    options: [
      { label: "Jay", value: "Jay" },
      { label: "Aljon", value: "Aljon" },
      { label: "Kalil", value: "Kalil" },
      { label: "Brent", value: "Brent" },
    ],
  },
]

const Shortlisted = () => {
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date From",
        field: "dateFrom",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date To",
        field: "dateTo",
        sort: "asc",
        width: 150,
      },
      {
        label: "AM/PM",
        field: "ampm",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "leaveType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        name: "Jose Rizal",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "Whole Day",
        leaveType: "Vacation Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Aljon Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "AM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Jay Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "PM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
    ],
  }

  const [modalView, setmodalView] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  document.title = "Data Tables | ByteFlare - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="Byteflare"
            title="Jobs"
            breadcrumbItem="Shortlisted"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">Shortlist candidates </CardTitle>

                  <div className="d-flex justify-content-between">
                    <SearchView className="d-flex justify-content-start mb-4">
                      <FormGroup className="filter-item">
                        <Label>Search Applicant</Label>
                        <Select
                          className="InputView"
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti()
                          }}
                          options={selectEmployee}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      <FormGroup className="filter-item">
                        <Label>Department</Label>
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">--Select Department--</option>
                          <option value="AE">IT Manager</option>
                          <option value="VI">Accounting</option>
                          <option value="MC">Web Developer</option>
                        </Input>
                      </FormGroup>
                      <FormGroup className="filter-item position-relative">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light position-absolute bottom-0"
                          onClick={tog_center}
                        >
                          <i className="fas fa-search"></i> Search
                        </Button>
                      </FormGroup>
                    </SearchView>
                  </div>

                  {/* <MDBDataTable responsive striped bordered data={data} /> */}
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Job title</th>
                          <th scope="col">Department</th>
                          <th scope="col">Status</th>
                          <th scope="col" colSpan="2">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Web developer</td>
                          <td>IT</td>
                          <td>
                            <span className="badge bg-success">Offered</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "21px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Web developer</td>
                          <td>IT</td>
                          <td>
                            <span className="badge bg-success">Offered</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "21px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Web developer</td>
                          <td>IT</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "21px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Web developer</td>
                          <td>IT</td>
                          <td>
                            <span className="badge bg-danger">Canceled</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "21px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Web developer</td>
                          <td>IT</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "21px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Web developer</td>
                          <td>IT</td>
                          <td>
                            <span className="badge bg-success">Offered</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "21px" }}
                              ></i>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>

              <Modal
                isOpen={modalView}
                toggle={toggleView}
                centered
                size="xl"
                className="modal-fullscreen"
              >
                <ModalBody>
                  <Row>
                    <Col xl={3}>
                      <div className="user-sidebar">
                        <Card>
                          <CardBody>
                            <div className="">
                              <div className="d-flex justify-content-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="btn dropdown-toggle text-muted fs-5"
                                    id="dropdownMenuButton"
                                    role="button"
                                  >
                                    <i className="mdi mdi-dots-vertical"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>Action</DropdownItem>
                                    <DropdownItem>Another action</DropdownItem>
                                    <DropdownItem>
                                      Something else here
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                            <div className="mt-n4 position-relative">
                              <div className="text-center">
                                <img
                                  src={user5}
                                  alt=""
                                  className="avatar-xl rounded-circle img-thumbnail"
                                />

                                <div className="mt-3">
                                  <h5 className="">John Doe</h5>
                                  <div>
                                    <Link to="#" className="text-muted m-1">
                                      web developer
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <div className="card-header">
                            <h4 className="card-title">Personal Information</h4>
                          </div>
                          <CardBody>
                            <ul className="list-unstyled mb-0">
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Name:{" "}
                                </span>
                                <span>John Doe</span>
                              </li>
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Gender:{" "}
                                </span>
                                <span>Male</span>
                              </li>
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Civil Status:{" "}
                                </span>
                                <span>Single</span>
                              </li>
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Age:{" "}
                                </span>
                                <span>21</span>
                              </li>
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Email:{" "}
                                </span>
                                <span>sample@gmail.com</span>
                              </li>
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Mobile:{" "}
                                </span>
                                <span>0999124546</span>
                              </li>
                              <li className="d-block p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Address:{" "}
                                </span>
                                <span>
                                  Blk 8 Lot 7 Argentina St. Ciudad Grande
                                  Dolores Taytay Rizal
                                </span>
                              </li>
                            </ul>
                            <hr />
                            <ul className="list-unstyled mb-0">
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Work Experience:{" "}
                                </span>
                                <span>5 years</span>
                              </li>
                              <li className="d-flex p-2 font-size-15">
                                <span
                                  className="text-muted"
                                  style={{ marginRight: "5px" }}
                                >
                                  Expected Salary:{" "}
                                </span>
                                <span>99,999</span>
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>

                    <Col xl={9}>
                      <Card>
                        <div className="card-header">
                          <h4 className="card-title">Detail</h4>
                        </div>
                        <CardBody>
                          <h4 className="font-size-15"> Introduction</h4>
                          <p className="text-muted">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of
                            Lorem Ipsum
                          </p>
                          <p className="text-muted">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of
                            Lorem Ipsum
                          </p>
                          <p className="text-muted">
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book. It has
                            survived not only five centuries, but also the leap
                            into electronic typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s with the
                            release of Letraset sheets containing Lorem Ipsum
                            passages, and more recently with desktop publishing
                            software like Aldus PageMaker including versions of
                            Lorem Ipsum
                          </p>
                          <p className="font-size-15"> Skills :</p>
                          <ul className="list-unstyled mb-0 pt-1">
                            <li className="py-1">
                              <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                              Donec vitae libero venenatis faucibus
                            </li>
                            <li className="py-1">
                              <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                              Quisque rutrum aenean imperdiet
                            </li>
                            <li className="py-1">
                              <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                              Integer ante a consectetuer eget
                            </li>
                            <li className="py-1">
                              <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                              Donec vitae libero venenatis faucibus
                            </li>
                            <li className="py-1">
                              <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                              Quisque rutrum aenean imperdiet
                            </li>
                            <li className="py-1">
                              <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                              Integer ante a consectetuer eget
                            </li>
                          </ul>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggleView}>
                      Close
                    </Button>{" "}
                    {/* <Button color="primary">Save changes</Button> */}
                  </ModalFooter>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Shortlisted
