import React, { useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Alert,
} from "reactstrap"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  DateFilter,
  EmployeeSummary,
  EmployeeSummaryTimeOff,
} from "constants/data"
import styled from "styled-components"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import PayrollMenu from "./payrollMenu"
import CreatePayrollModal from "./create"
import { fm } from "helpers"
import classNames from "classnames"
import PayChart from "./chart"
import { useNavigate } from "react-router-dom"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

const SummaryContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;

  .emp-name {
    h5 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  .off-date {
    color: #e7502c;
  }

  .emp-summary {
    height: 440px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .pay-status {
    width: 100px;
    border-radius: 100px;
  }

  .emp_details {
    cursor: pointer;
  }

  .paid-emps {
    width: 100%;
    /* height: 100px; */
    div {
      position: relative;
      height: 30px;
      border: 5px solid white;
      border-radius: 20px;
    }
    & div:nth-child(1) {
      width: 68%;
      background-color: #44c5e2;
    }

    & div:nth-child(2) {
      width: 17%;
      background-color: #f9a746;
      margin-left: -15px;
    }
    & div:nth-child(3) {
      width: 15%;
      background-color: #8382de;
      margin-left: -15px;
    }
  }

  .badge-paid {
    color: #44c5e2;
  }
  .badge-pending {
    color: #f9a746;
  }
  .badge-unpaid {
    color: #8382de;
  }
`

const Payroll = () => {
  document.title = "HRIS | PAYROLL"
  const [singlebtn, setSinglebtn] = useState(false)
  const [filter, setFilter] = useState("Year")
  const [modal_center, setmodal_center] = useState(false)
  const [create_model, setCreate_model] = useState(false)

  const starStyle = {}

  const navigate = useNavigate()

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const tog_create = () => {
    setmodal_center(false)
    setCreate_model(!create_model)
    removeBodyCss()
  }
  const goPayslip = () => navigate("/payslip")

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="Payroll"
            breadcrumbItem="Summary"
          />
          <SummaryContainer>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <h2>Payroll</h2>
                    <br />
                    <CardTitle className="h4"></CardTitle>

                    <Container fluid>
                      <Row>
                        <Col lg={12} className="mb-3">
                          <div className="d-flex justify-content-between">
                            <h4> Payroll Summary</h4>
                            <div className="d-flex justify-content-start">
                              <Dropdown
                                isOpen={singlebtn}
                                toggle={() => setSinglebtn(!singlebtn)}
                              >
                                <DropdownToggle
                                  className="btn btn-secondary"
                                  caret
                                >
                                  {filter}{" "}
                                  <i className="mdi mdi-chevron-down" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  {Object.keys(DateFilter).map(data => (
                                    <DropdownItem
                                      key={data}
                                      onClick={() =>
                                        setFilter(DateFilter[data])
                                      }
                                    >
                                      {DateFilter[data]}
                                    </DropdownItem>
                                  ))}
                                </DropdownMenu>
                              </Dropdown>
                              <FilterTxt
                                name="search"
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                              />
                            </div>
                            <div>
                              <Button
                                color="primary"
                                className="waves-effect waves-light d-flex align-items-center justify-content-between"
                                onClick={tog_center}
                              >
                                <i className="typcn typcn-document-add me-1" />
                                Create Payroll
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col lg="8">
                          <div id="mix-line-bar" className="e-chart">
                            <PayChart />
                          </div>
                        </Col>
                        <Col lg="4">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4>Payment Status</h4>
                          </div>
                          <div className="mt-2 d-flex align-items-center justify-content-start">
                            <h4>2,400 </h4>
                            <h6 className="ms-1">Employees</h6>
                          </div>
                          <div className="paid-emps mt-2 d-flex align-items-center justify-content-start">
                            <div />
                            <div />
                            <div />
                          </div>
                          <div className="mt-2 d-flex align-items-center justify-content-start">
                            <div className=" d-flex align-items-start justify-content-start">
                              <i className="mdi mdi-circle-slice-8 mx-2 badge-paid" />
                              <div className="d-flex flex-column align-items-start justify-content-start">
                                <h5>68%</h5>
                                <p className="fs-6">Successfully Paid</p>
                              </div>
                            </div>
                            <div className=" d-flex align-items-start justify-content-start">
                              <i className="mdi mdi-circle-slice-8 mx-2 badge-pending" />
                              <div className="d-flex flex-column align-items-start justify-content-start">
                                <h5>17%</h5>
                                <p className="fs-6">Pending</p>
                              </div>
                            </div>
                            <div className=" d-flex align-items-start justify-content-start">
                              <i className="mdi mdi-circle-slice-8 mx-2 badge-unpaid" />
                              <div className="d-flex flex-column align-items-start justify-content-start">
                                <h5>15%</h5>
                                <p className="fs-6">Unpaid</p>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="d-flex align-items-center justify-content-between">
                            <h4>Employee Time-off</h4>
                            <p className="fs-6 mb-0">View all</p>
                          </div>
                          <div>
                            <table className="table table-hover table-centered table-nowrap mb-0">
                              {EmployeeSummaryTimeOff.map((item, i) => (
                                <tr key={i}>
                                  <td colSpan="7">
                                    <div className="d-flex">
                                      {item.photo === "user2" && (
                                        <img
                                          src={user2}
                                          alt=""
                                          className="avatar-xs rounded-circle me-2"
                                        />
                                      )}
                                      {item.photo === "user3" && (
                                        <img
                                          src={user3}
                                          alt=""
                                          className="avatar-xs rounded-circle me-2"
                                        />
                                      )}

                                      {item.photo === "user4" && (
                                        <img
                                          src={user4}
                                          alt=""
                                          className="avatar-xs rounded-circle me-2"
                                        />
                                      )}
                                      {item.photo === "user5" && (
                                        <img
                                          src={user5}
                                          alt=""
                                          className="avatar-xs rounded-circle me-2"
                                        />
                                      )}
                                      {item.photo === "user6" && (
                                        <img
                                          src={user6}
                                          alt=""
                                          className="avatar-xs rounded-circle me-2"
                                        />
                                      )}

                                      <span className="emp-name">
                                        <h5> {item.name}</h5>
                                        <p className="fs-6"> {item.off_type}</p>
                                      </span>
                                    </div>
                                  </td>
                                  <td className="off-date text-end">
                                    {item.off_date}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="emp-summary">
                  <CardBody>
                    <h4 className="card-title mb-4">EMPLOYEE SUMMARY</h4>
                    <div className="table-responsive">
                      <table className="table table-hover table-centered table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col"></th>
                            <th scope="col" colSpan="7">
                              Employee Name
                            </th>
                            <th scope="col">Role</th>
                            <th scope="col" className="text-end">
                              Gross
                            </th>
                            <th scope="col" className="text-end">
                              Taxes
                            </th>
                            <th scope="col" className="text-end">
                              Net Salary
                            </th>
                            <th scope="col" className="text-center">
                              Performance
                            </th>
                            <th scope="col" className="">
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {EmployeeSummary.map((item, i) => (
                            <tr
                              key={i}
                              className="emp_details"
                              onClick={() => goPayslip()}
                            >
                              <th scope="row">#{item.index}</th>
                              <td colSpan="7">
                                <div className="d-flex">
                                  {item.photo === "user2" && (
                                    <img
                                      src={user2}
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />
                                  )}
                                  {item.photo === "user3" && (
                                    <img
                                      src={user3}
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />
                                  )}

                                  {item.photo === "user4" && (
                                    <img
                                      src={user4}
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />
                                  )}
                                  {item.photo === "user5" && (
                                    <img
                                      src={user5}
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />
                                  )}
                                  {item.photo === "user6" && (
                                    <img
                                      src={user6}
                                      alt=""
                                      className="avatar-xs rounded-circle me-2"
                                    />
                                  )}

                                  <span className="emp-name">
                                    <h5> {item.name}</h5>
                                    <p className="fs-6"> {item.email}</p>
                                  </span>
                                </div>
                              </td>
                              <td>{item.role}</td>
                              <td className="text-end">
                                {fm.from(item.gross)}
                              </td>
                              <td className="text-end">
                                <span className="text-danger">
                                  -{fm.from(item.taxes)}
                                </span>
                              </td>
                              <td className="text-end">
                                {fm.from(item.gross - item.taxes)}
                              </td>
                              <td className="text-center">
                                <span
                                  className={classNames(
                                    "text-center d-flex align-items-center justify-content-center",
                                    {
                                      "text-success":
                                        item.performance === "Good",
                                      "text-warning":
                                        item.performance === "Moderate",
                                      "text-danger":
                                        item.performance === "Poor",
                                    }
                                  )}
                                >
                                  <i
                                    className="mdi mdi-circle-slice-8 mx-2"
                                    style={starStyle}
                                  />
                                  {item.performance}
                                </span>
                              </td>
                              <td className="text-center">
                                <Alert
                                  className="pay-status mb-0"
                                  color={classNames({
                                    success: item.pay_status === 1,
                                    warning: item.pay_status === 2,
                                    danger: item.pay_status === 0,
                                  })}
                                >
                                  <strong>
                                    {item.pay_status === 0 && "UNPAID"}
                                    {item.pay_status === 1 && "PAID"}
                                    {item.pay_status === 2 && "PENDING"}
                                  </strong>
                                </Alert>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </SummaryContainer>
        </div>
        <Modal isOpen={modal_center} toggle={tog_center} centered>
          <ModalHeader className="mt-0" toggle={tog_center}>
            Create Payroll
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <Row className="mb-3">
                  <label className="col-md-4 col-form-label">
                    Payroll Date
                  </label>
                  <div className="col-md-8">
                    <select className="form-control">
                      <option>Select</option>
                      <option>January 15-30</option>
                      <option>February 1-15</option>
                      <option>February 16-28</option>
                    </select>
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>Disable Loan Deduction</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>Cash Advance</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>HDMF Calamity Loan </Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>HDMF Loan</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>SSS Calamity Loan</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>SSS Salary Loan</Label>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Label>Goverment Deduction </Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>30%</Label>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="checkbox-wrapper-mail">
                      <Input type="checkbox" id="chk19" />
                      <Label htmlFor="chk19" className="toggle" />
                    </div>
                  </Col>
                  <Col>
                    <Label>70%</Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={tog_create}>
              Create
            </Button>
            <Button color="secondary" onClick={tog_center}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <CreatePayrollModal isOpen={create_model} tog_create={tog_create} />
      </div>
    </React.Fragment>
  )
}

export default Payroll
