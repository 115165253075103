import React, { useState } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
} from "reactstrap"

import "chartist/dist/scss/chartist.scss"

import { BENEFITS, DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { fm } from "helpers"
import classNames from "classnames"

const BenefitsList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Benefits | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="Settings"
            breadcrumbItem="Benefits"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row>
                    <p className="card-title-desc">List of Benefits</p>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <h2>Benefits</h2>
                    </Col>
                    <Col lg={2} className="text-end">
                      <Button
                        onClick={toggleAdd}
                        size="md"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Benefits
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Benefit</th>
                          <th>Benefit Type</th>
                          <th className="text-center">Percentage</th>
                          <th className="text-center">Min - Max / Value</th>
                          <th>Contribution</th>
                          <th className="text-center">Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BENEFITS.map((b, i) => (
                          <tr key={i}>
                            <td>{b.sub_type}</td>
                            <td>{b.type}</td>
                            <td
                              className={classNames("text-center", {
                                "text-success": b.percentage,
                                "text-info": !b.percentage,
                              })}
                            >
                              {b.percentage ? "YES" : "NO"}
                            </td>
                            <th className="text-center">
                              {b.percentage
                                ? `${b.value}%`
                                : `${b.min ? fm.from(b.min) : "BELOW"} - ${
                                    b.max ? fm.from(b.max) : "ABOVE"
                                  }`}
                            </th>
                            <th className="text-start">
                              <div className="d-flex flex-row align-items-start justify-content-start">
                                {b.contribution &&
                                  `Employer: ${fm.from(b.employer)}`}
                                <br />
                                {b.contribution &&
                                  `Employee: ${fm.from(b.employee)}`}
                              </div>
                            </th>
                            <th className="text-center">
                              <span
                                className={classNames(
                                  "text-center d-flex align-items-center justify-content-center",
                                  {
                                    "text-success": b.status,
                                    "text-warning": !b.status,
                                  }
                                )}
                              >
                                <i className="mdi mdi-circle-slice-8 mx-2" />
                                {b.status ? "ACTIVE" : "IN-ACTIVE"}
                              </span>
                            </th>
                            <th>
                              <i
                                onClick={toggleView}
                                className="typcn typcn-user-outline"
                                style={{ fontSize: "25px" }}
                              ></i>{" "}
                              <i
                                onClick={toggleEdit}
                                className="typcn typcn-edit"
                                style={{ fontSize: "25px" }}
                              ></i>
                              <i
                                onClick={toggleDelete}
                                className="typcn typcn-trash"
                                style={{ fontSize: "25px" }}
                              ></i>
                            </th>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* VIEW MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalView} toggle={toggleView} size="lg">
                <ModalHeader className="mt-0" toggle={toggleView}>
                  View Benefit
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Benefit
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Benefit"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Benefit Type
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Enter Benefit Type"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label className="col-md-2 col-form-label">
                        Percentage
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option disabled>Select</option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Percentage Value
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Minimum Value
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Maximum Value
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label className="col-md-2 col-form-label">
                        Fixed Contribution
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option disabled>Select</option>
                          <option value={1}>Yes</option>
                          <option value={0}>No</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Employer Contribution
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Employee Contribution
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Value"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Benefits
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Benefit
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Benefit"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Benefit Type
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Enter Benefit Type"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                          Percentage
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option disabled>Select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Percentage Value
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Minimum Value
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Maximum Value
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                          Fixed Contribution
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option disabled>Select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Employer Contribution
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Employee Contribution
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success">
                          Save
                        </button>
                        <button type="button" className="btn btn-danger">
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Benefit
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Benefit
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Benefit"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Benefit Type
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="number"
                            placeholder="Enter Benefit Type"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                          Percentage
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option disabled>Select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Percentage Value
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Minimum Value
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Maximum Value
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label className="col-md-2 col-form-label">
                          Fixed Contribution
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option disabled>Select</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Employer Contribution
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3 d-flex align-items-center justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Employee Contribution
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Value"
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BenefitsList
