import React, { useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const PerformanceReview = () => {
  document.title = "Performance | Performance Review"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Performance Review"
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">
                      Employee Basic Information
                    </CardTitle>
                    <p className="card-title-desc">Lorem ipsum dollar</p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Name
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Emp ID
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="EMP - 001"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Department
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Date of Join
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Designation
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Date of Confirmation
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Qualification
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Previous years of Exp
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        RO's Name
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        RO Designation:
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">
                      Professional Excellence
                    </CardTitle>
                    <p className="card-title-desc">
                      Professional excellence means being recognized for your
                      skills as a commu- nicator and serving as a role model to
                      others
                    </p>
                  </div>

                  <div className="table-responsive">
                    <Table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Key Result Area</th>
                          <th>Key Performance Indicators</th>
                          <th>Weightage</th>
                          <th>Percentage achieved (self Score)</th>
                          <th>Points Scored - (self)</th>
                          <th>Percentage achieved(RO's Score)</th>
                          <th>Points Scored(RO)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Production</td>
                          <td>Quality / TAT (turn around time)</td>
                          <td>30</td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Process Improvement</td>
                          <td>PMS,New Ideas</td>
                          <td>30</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Team Management</td>
                          <td>
                            Team Productivity,dynaics,attendance,attrition
                          </td>
                          <td>10</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Knowledge Sharing</td>
                          <td>Sharing the knowledge for team productivity</td>
                          <td>5</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Reporting and Communication</td>
                          <td>Emails/Calls/Reports and Other Communication</td>
                          <td>5</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            colSpan={3}
                            className="text-center table-light"
                          >
                            Total
                          </th>
                          <td>85</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">Personal Excellence</CardTitle>
                    <p className="card-title-desc">
                      Personal Excellence means being recognized for your skills
                      as a commu- nicator and serving as a role model to others
                    </p>
                  </div>

                  <div className="table-responsive">
                    <Table className="table table-bordered mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Personal Attributes</th>
                          <th>Key Indicators</th>
                          <th>Weightage</th>
                          <th>Percentage achieved (self Score)</th>
                          <th>Points Scored - (self)</th>
                          <th>Percentage achieved(RO's Score)</th>
                          <th>Points Scored(RO)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row" rowSpan={2}>
                            1
                          </th>
                          <td rowSpan={2}>Attendance</td>
                          <td>Planned or Unplanned Leaves</td>
                          <td>2</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Time Consciousness</td>
                          <td>2</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row" rowSpan={2}>
                            2
                          </th>
                          <td rowSpan={2}>Attitude & Behavior</td>
                          <td>Team Collaboration</td>
                          <td>2</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <td>Professionalism & Responsiveness</td>
                          <td>2</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Policy & Procedures</td>
                          <td>Adherence to policies and procedures</td>
                          <td>2</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Initiatives</td>
                          <td>Special Efforts, Suggestions,Ideas,etc.</td>
                          <td>2</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Continuous Skill Improvement</td>
                          <td>
                            Preparedness to move to next level & Training
                            utilization
                          </td>
                          <td>3</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                          <td>
                            {" "}
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th
                            scope="row"
                            colSpan={3}
                            className="text-center table-light"
                          >
                            Total
                          </th>
                          <td>15</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                          <td>0</td>
                        </tr>
                        <tr>
                          <th scope="row" colSpan={3} className="text-center">
                            Total Percentage(%)
                          </th>
                          <td colSpan={5}>0</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">
                      Special Initiatives, Achievements, contributions if any
                    </CardTitle>
                    <p className="card-title-desc">Lorem ipsum dollar</p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={4}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        By Self
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        RO's Comment
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        HOD's Comment
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">Comments on the role</CardTitle>
                    <p className="card-title-desc">
                      alterations if any requirred like addition/deletion of
                      responsibilitiesr
                    </p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={4}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        By Self
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        RO's Comment
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={4}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        HOD's Comment
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">Comments on the role</CardTitle>
                    <p className="card-title-desc">
                      alterations if any requirred like addition/deletion of
                      responsibilitiesr
                    </p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Strengths
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Area's for Improvement
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">
                      Appraisee's Strengths and Areas for Improvement perceived
                      by the Head of the Department
                    </CardTitle>
                    <p className="card-title-desc">Lorem ipsum dollar</p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Strengths
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={6}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Area's for Improvement
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">Personal Goals</CardTitle>
                    <p className="card-title-desc">Lorem ipsum dollar</p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={7}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Goal Achieved during last year
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                    <Col lg={5}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Goal set for current year
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="text-center">
                    <CardTitle className="h4">Personal Updates</CardTitle>
                    <p className="card-title-desc">Lorem ipsum dollar</p>
                  </div>

                  <Row className="mb-1">
                    <Col lg={7}>
                      <div>
                        <label
                          htmlFor="example-text-input"
                          className="col-form-label"
                        >
                          Last Year
                        </label>
                      </div>
                      <div>
                        <label
                          htmlFor="example-text-input"
                          className="col-form-label"
                        >
                          Higher Studies/Certifications?
                        </label>
                      </div>
                      <div>
                        <label
                          htmlFor="example-text-input"
                          className="col-form-label"
                        >
                          Health Issues?
                        </label>
                      </div>
                      <div>
                        <label
                          htmlFor="example-text-input"
                          className="col-form-label"
                        >
                          Others
                        </label>
                      </div>
                    </Col>
                    <Col lg={5}>
                      <label
                        htmlFor="example-text-input"
                        className="col-form-label"
                      >
                        Goal set for current year
                      </label>
                      <div>
                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder=""
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PerformanceReview
