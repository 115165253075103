import React, { useState, Component } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"

const GoalType = () => {
  const [modal_center, setmodal_center] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const [drp_secondary, setDrp_secondary] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Goal | Goal List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Goal List"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Goal List</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <FilterTxt
                        name="search"
                        type="text"
                        className="form-control w-100 h-100"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={5}>
                      <div className="btn-group mt-1">
                        <ButtonDropdown
                          isOpen={drp_secondary}
                          toggle={() => setDrp_secondary(!drp_secondary)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-outline-info"
                          >
                            <i
                              className="typcn typcn-th-list"
                              style={{ fontSize: "18px" }}
                            />{" "}
                            Select Status <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Active</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Inactive</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={tog_center}
                          size="md"
                          color="primary"
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="typcn typcn-folder-add"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                          Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* ADD MODAL */}
                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Create Goal List
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Goal Type
                        </label>
                        <div className="mb-2">
                          <select className="form-control">
                            <option>-- Please Select --</option>
                            <option>Event Goal</option>
                            <option>Project Goal</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Subject
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Target Achievement
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Start Date
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            End Date
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Description
                        </label>
                        <div className="col-md-10 mb-2">
                          <textarea
                            id="txtAddress1Billing"
                            name="txtAddress1Billing"
                            rows="4"
                            className="form-control"
                            placeholder=""
                          ></textarea>
                        </div>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Status
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>Active</option>
                              <option>Inactive</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Add
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* EDIT MODAL */}
                  <Modal
                    isOpen={modalEdit}
                    toggle={toggleEdit}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={toggleEdit}>
                      Edit Goal List
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Goal Type
                        </label>
                        <div className="mb-2">
                          <select className="form-control">
                            <option>-- Please Select --</option>
                            <option>Event Goal</option>
                            <option>Project Goal</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Subject
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Target Achievement
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Start Date
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            End Date
                          </label>
                          <div className="mb-2">
                            <input
                              className="form-control"
                              type="text"
                              placeholder=""
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Description
                        </label>
                        <div className="col-md-10 mb-2">
                          <textarea
                            id="txtAddress1Billing"
                            name="txtAddress1Billing"
                            rows="4"
                            className="form-control"
                            placeholder=""
                          ></textarea>
                        </div>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Status
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>Active</option>
                              <option>Inactive</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggleEdit}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={toggleEdit}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* DELETE MODAL */}

                  <Col sm={6} md={4} xl={3}>
                    <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
                      <ModalHeader className="mt-0" toggle={toggleDelete}>
                        Are you sure want to delete Goal List?
                      </ModalHeader>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger">
                          Delete
                        </button>
                        <button type="button" className="btn btn-secondary">
                          Close
                        </button>
                      </div>
                    </Modal>
                  </Col>

                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Goal Type</th>
                          <th scope="col">Subject</th>
                          <th scope="col">Target Achievement</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col">Description</th>
                          <th scope="col">Status</th>
                          <th scope="col">Progress</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Event Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Project Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Event Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Project Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Event Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Project Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>Event Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Project Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>Event Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">10</th>
                          <td>Project Goal</td>
                          <td>Subject Goal</td>
                          <td>Lorem ipsum dollar</td>
                          <td>8/15/2023</td>
                          <td>9/25/2023</td>
                          <td>Lorem ipsum dollar</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>Progress</td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GoalType
