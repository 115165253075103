import React, { useState } from "react"
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap"

const CreatePayrollModal = ({ isOpen, tog_create }) => {
  const [btnsecondary1, setBtnsecondary1] = useState(false)

  return (
    <Modal isOpen={isOpen} toggle={tog_create} size="xl">
      <ModalHeader className="mt-0" toggle={tog_create}>
        <div className="d-flex align-items-center">
          <i className="mdi mdi-calendar ms-1"></i>
          <span className="ms-2">January 15-30 2023 Payroll</span>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={6}>
            <div className="d-flex align-items-center ">
              <div className="input-group pay-txtSearch">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text"
                    id="validationTooltipUsernamePrepend"
                  >
                    <i className="mdi mdi-account-search-outline " />
                  </span>
                </div>
                <Input
                  type="text"
                  className="form-control "
                  id="validationTooltipUsername"
                  placeholder="Search employee by name or ID"
                />
              </div>
              <Button
                color="warning"
                outline
                className="waves-effect waves-light ms-3"
              >
                Suspened Employees - 29
              </Button>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex align-items-center justify-content-end">
              <div className="btn-group">
                <Dropdown
                  isOpen={btnsecondary1}
                  toggle={() => setBtnsecondary1(!btnsecondary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-outline-info">
                    Filter by <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem>Action</DropdownItem>
                    <DropdownItem>Another action</DropdownItem>
                    <DropdownItem>Something else here</DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem>Separated link</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Button
                  color="info"
                  outline
                  className="waves-effect waves-light d-flex align-items-center justify-content-center ms-3"
                >
                  <i className="ti-arrow-up" />
                  <span className="ms-2">Bulk Upload</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xl={4} className="px-0">
            <h4 className="card-title mb-2">Employee Details</h4>
            <div className="table-responsive">
              <table className="table table-hover table-centered table-bordered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <div className="checkbox-wrapper-mail mx-2">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle" />
                        </div>
                        14256
                      </div>
                    </th>
                    <td>
                      <div>Philip Smead</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <div className="checkbox-wrapper-mail mx-2">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle" />
                        </div>
                        5147
                      </div>
                    </th>
                    <td>
                      <div>Brent Shipley</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <div className="checkbox-wrapper-mail mx-2">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle" />
                        </div>
                        14258
                      </div>
                    </th>
                    <td>
                      <div>Robert Sitton</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <div className="checkbox-wrapper-mail mx-2">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle" />
                        </div>
                        14257
                      </div>
                    </th>
                    <td>
                      <div>
                        Alberto Jackson
                        <i className="ion ion-md-add-circle-outline" />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <div className="checkbox-wrapper-mail mx-2">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle" />
                        </div>
                        14251
                      </div>
                    </th>
                    <td>
                      <div>David Sanchez</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="d-flex align-items-center">
                        <div className="checkbox-wrapper-mail mx-2">
                          <Input type="checkbox" id="chk19" />
                          <Label htmlFor="chk19" className="toggle" />
                        </div>
                        14253
                      </div>
                    </th>
                    <td>
                      <div>Philip Smead</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2 mx-3">
              <div className=" ">
                <h6>Total Employees</h6>
                <h5>157</h5>
              </div>
              <div className="text-end">
                <h6>Total Amount</h6>
                <h5>₱3,940,547.44</h5>
              </div>
            </div>
          </Col>
          <Col xl={4} className="px-0">
            <h4 className="card-title mb-2 text-center">Earnings</h4>
            <div className="table-responsive">
              <table className="table table-hover table-centered table-bordered table-nowrap mb-0 text-end">
                <thead>
                  <tr>
                    <th scope="col">Basic Salary</th>
                    <th scope="col">Health Insurance</th>
                    <th scope="col">Total Salary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">10500.00</td>
                    <td>
                      <div>600.00</div>
                    </td>
                    <th>11100.00</th>
                  </tr>
                  <tr>
                    <td scope="row">9500.00</td>
                    <td>
                      <div>800.00</div>
                    </td>
                    <th>10300.00</th>
                  </tr>
                  <tr>
                    <td scope="row">11400.00</td>
                    <td>800.00</td>
                    <th>12200.00</th>
                  </tr>
                  <tr>
                    <td scope="row">10600.00</td>
                    <td>510.00</td>
                    <th>11200.00</th>
                  </tr>
                  <tr>
                    <td scope="row">10530.00</td>
                    <td>580.00</td>
                    <th>11100.00</th>
                  </tr>
                  <tr>
                    <td scope="row">8800.00</td>
                    <td>640.00</td>
                    <th>81400.00</th>
                  </tr>
                  <tr style={{ height: "60px" }}>
                    <td scope="row">941100.00</td>
                    <td>22000.00</td>
                    <th>122500.00</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col xl={2} className="px-0">
            <h4 className="card-title mb-2 text-center">Additions</h4>
            <div className="table-responsive">
              <table className="table table-hover table-centered table-bordered table-nowrap mb-0 text-end">
                <thead>
                  <tr>
                    <th scope="col">Total Additions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1600.00</th>
                  </tr>
                  <tr>
                    <th>1150.00</th>
                  </tr>
                  <tr>
                    <th>1240.00</th>
                  </tr>
                  <tr>
                    <th>2300.00</th>
                  </tr>
                  <tr>
                    <th>2700.00</th>
                  </tr>
                  <tr>
                    <th>1520.00</th>
                  </tr>
                  <tr style={{ height: "60px" }}>
                    <th>58500.00</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
          <Col xl={2} className="px-0">
            <h4 className="card-title mb-2 text-center">Deductions</h4>
            <div className="table-responsive">
              <table className="table table-hover table-centered table-bordered table-nowrap mb-0 text-end">
                <thead>
                  <tr>
                    <th scope="col">Total Deductions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1400.00</th>
                  </tr>
                  <tr>
                    <th>1520.00</th>
                  </tr>
                  <tr>
                    <th>1720.00</th>
                  </tr>
                  <tr>
                    <th>1250.00</th>
                  </tr>
                  <tr>
                    <th>1700.00</th>
                  </tr>
                  <tr>
                    <th>2250.00</th>
                  </tr>
                  <tr style={{ height: "60px" }}>
                    <th>64640.00</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="d-flex align-items-center mt-3">
              <p>Showing 1-6 of 45 results</p>
              <div className="ms-4">
                <Pagination aria-label="Page navigation example">
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">
                      2 <span className="sr-only">(current)</span>
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>

                  <PaginationItem>
                    <PaginationLink href="#">4</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">....</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">8</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="d-flex align-items-center justify-content-end mt-3">
              <h5>₱4,304,940.44</h5>
              <Button
                color="primary"
                className="btn btn-primary  waves-effect waves-light d-flex align-items-center justify-content-space ms-4"
                onClick={tog_create}
              >
                Submit Payroll
                <i className="ion ion-md-arrow-round-forward  ms-3" />
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default CreatePayrollModal
