import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardTitle,
  CardImg,
  CardHeader,
} from "reactstrap"
import { Link } from "react-router-dom"

import {
  DateFilter,
  EmployeeSummary,
  EmployeeSummaryTimeOff,
} from "constants/data"
import styled from "styled-components"
// Custom Scrollbar
import SimpleBar from "simplebar-react"
import PayChart from "../Payroll/chart"

// import images
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import img4 from "../../assets/images/small/img-5.jpg"

// Carousel
// import Slide from "../Ui/CarouselTypes/"
// import Slidewithcontrol from "./CarouselTypes/slidewithcontrol"
// import Slidewithindicator from "./CarouselTypes/slidewithindicator"
import Slidewithcaption from "../Ui/CarouselTypes/slidewithcaption"
// import Slidewithfade from "./CarouselTypes/slidewithfade"

// Charts
import LineAreaChart from "../AllCharts/apex/lineareachart"
import RadialChart from "../AllCharts/apex/apexdonut"
import Apexdonut from "../AllCharts/apex/apexdonut1"
import SparkLine from "../AllCharts/sparkline/sparkline"
import SparkLine1 from "../AllCharts/sparkline/sparkline1"
import Salesdonut from "../AllCharts/apex/salesdonut"

import "chartist/dist/scss/chartist.scss"

//i18n
import { withTranslation } from "react-i18next"
import { fm } from "helpers"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

const LeaveName = styled.span`
  p {
    margin-bottom: 0px;
  }
`

const Dashboard = props => {
  const [menu, setMenu] = useState(false)
  const [singlebtn, setSinglebtn] = useState(false)
  const [filter, setFilter] = useState("Year")

  const toggle = () => {
    setMenu(!menu)
  }

  document.title = "Byteflare Solutions"
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to ByteFlare Dashboard
                  </li>
                </ol>
              </Col>

              {/* <Col md="4">
                <div className="float-end d-none d-md-block">
                  <Dropdown isOpen={menu} toggle={toggle}>
                    <DropdownToggle
                      color="primary"
                      className="btn btn-primary dropdown-toggle waves-effect waves-light"
                    >
                      <i className="mdi mdi-cog me-2"></i> Settings
                    </DropdownToggle>
                    <DropdownMenu end>
                      <DropdownItem tag="a" href="#">
                        Action
                      </DropdownItem>
                      <DropdownItem tag="a" href="#">
                        Another action
                      </DropdownItem>
                      <DropdownItem tag="a" href="#">
                        Something else here
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem tag="a" href="#">
                        Separated link
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Col> */}
            </Row>
          </div>
          <Row>
            <Col xl={8} md={12}>
              <Row>
                <Col xl={4} md={6}>
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon1} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Employees
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          1,685{" "}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        <div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 12%</p>
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon2} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Late
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          52mins{" "}
                          <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                        </h4>
                        <div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 28%</p>
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card className="mini-stat bg-primary text-white">
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon3} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          PAYROLL STATS
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {fm.from(4304940.44)}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        <div className="mini-stat-label bg-info">
                          <p className="mb-0"> 00%</p>
                        </div>
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12} className="mb-3">
                      <div className="d-flex justify-content-between">
                        <h4> Payroll Summary</h4>
                        <div className="d-flex justify-content-start">
                          <Dropdown
                            isOpen={singlebtn}
                            toggle={() => setSinglebtn(!singlebtn)}
                          >
                            <DropdownToggle className="btn btn-secondary" caret>
                              {filter} <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {Object.keys(DateFilter).map(data => (
                                <DropdownItem
                                  key={data}
                                  onClick={() => setFilter(DateFilter[data])}
                                >
                                  {DateFilter[data]}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </Dropdown>
                          <FilterTxt
                            name="search"
                            type="text"
                            className="form-control"
                            placeholder="Search..."
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg="12">
                      <div id="mix-line-bar" className="e-chart">
                        <PayChart />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4} md={12}>
              <Card>
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Announcement</h4>
                  </div>

                  <Card className="mb-0">
                    <CardBody className="mb-0">
                      {/* <CardTitle className="h4">Announcement title</CardTitle>
                      <h6 className="card-subtitle font-14 text-muted">
                        Announcement card subtitle
                      </h6> */}
                      {/* <p className="card-title-desc"></p> */}
                      <Slidewithcaption />
                    </CardBody>
                  </Card>

                  {/* <div className="float-end">
                    <Link to="#" className="text-primary">
                      Read More <i className="mdi mdi-arrow-right h5"></i>
                    </Link>
                  </div> */}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4>Employee Leaves</h4>
                    <p className="fs-6 mb-0">View all</p>
                  </div>
                  <div>
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      {EmployeeSummaryTimeOff.map((item, i) => (
                        <tr key={i}>
                          <td colSpan="7">
                            <div className="d-flex">
                              {item.photo === "user2" && (
                                <img
                                  src={user2}
                                  alt=""
                                  className="avatar-xs rounded-circle me-2"
                                />
                              )}
                              {item.photo === "user3" && (
                                <img
                                  src={user3}
                                  alt=""
                                  className="avatar-xs rounded-circle me-2"
                                />
                              )}

                              {item.photo === "user4" && (
                                <img
                                  src={user4}
                                  alt=""
                                  className="avatar-xs rounded-circle me-2"
                                />
                              )}
                              {item.photo === "user5" && (
                                <img
                                  src={user5}
                                  alt=""
                                  className="avatar-xs rounded-circle me-2"
                                />
                              )}
                              {item.photo === "user6" && (
                                <img
                                  src={user6}
                                  alt=""
                                  className="avatar-xs rounded-circle me-2"
                                />
                              )}

                              <LeaveName className="emp-name">
                                <h5> {item.name}</h5>
                                <p className="fs-6"> {item.off_type}</p>
                              </LeaveName>
                            </div>
                          </td>
                          <td className="off-date text-end">{item.off_date}</td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">List of Employee</h4>
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col" colSpan="2">
                            Department
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>doelorem@gmail.com</td>
                          <td>
                            <span className="badge bg-success">Accounting</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14257</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Brent Shipley
                            </div>
                          </td>
                          <td>loremipsum@gmail.com</td>
                          <td>
                            <span className="badge bg-warning">IT</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14258</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Robert Sitton
                            </div>
                          </td>
                          <td>doelorem@gmail.com</td>
                          <td>
                            <span className="badge bg-success">Accounting</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14259</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alberto Jackson
                            </div>
                          </td>
                          <td>doelorem@gmail.com</td>
                          <td>
                            <span className="badge bg-primary">Marketing</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14260</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              David Sanchez
                            </div>
                          </td>
                          <td>loremipsum@gmail.com</td>
                          <td>
                            <span className="badge bg-success">Accounting</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14261</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>loremipsum@gmail.com</td>
                          <td>
                            <span className="badge bg-warning">IT</span>
                          </td>
                          <td>
                            <div>
                              <Link to="#" className="btn btn-primary btn-sm">
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Department</h4>
                  <div
                    id="ct-donut"
                    className="ct-chart wid pt-4"
                    style={{ height: "255px" }}
                  >
                    <Salesdonut />
                  </div>
                  <div className="mt-4">
                    <table className="table mb-0">
                      <tbody>
                        <tr>
                          <td>
                            <span className="badge bg-primary">Marketing</span>
                          </td>
                          <td>Marketing</td>
                          <td className="text-end">54.5%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-success">Accounting</span>
                          </td>
                          <td>Accounting</td>
                          <td className="text-end">28.0%</td>
                        </tr>
                        <tr>
                          <td>
                            <span className="badge bg-warning">IT</span>
                          </td>
                          <td>IT</td>
                          <td className="text-end">17.5%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Dashboard)
