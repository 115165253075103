import React, { useState } from "react";

import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import Dropzone from "react-dropzone";

import classnames from "classnames";
import { Link } from "react-router-dom";

// import images
import logoSm from "../../assets/images/logo-sm.png";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ApplicantForm = () => {
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabProgress, setactiveTabProgress] = useState(1);
  const [progressValue, setprogressValue] = useState(25);
  const [activeTabVartical, setoggleTabVertical] = useState(1);
  const [selectedFiles, setselectedFiles] = useState([]);

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles(files);
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab);
      }
    }
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTabProgress(tab);

        if (tab === 1) {
          setprogressValue(25);
        }
        if (tab === 2) {
          setprogressValue(50);
        }
        if (tab === 3) {
          setprogressValue(75);
        }
        if (tab === 4) {
          setprogressValue(100);
        }
      }
    }
  }
  document.title = "Form Wizard ByteFlare ";
  return (
    <React.Fragment>
      <div className="page-content pt-sm-5">
        <Container fluid={true}>
          <Row  className="justify-content-center">
            <Col sm="12">
            <Card className="overflow-hidden">
                
              <div className="bg-primary">
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">Application Form</h5>
                    <p className="text-white-50">Fill up all fields.</p>
                  </div>
                </div>
                <CardBody>
                  {/* <h4 className="card-title mb-4">Basic Wizard</h4> */}
                  <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}>
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              setactiveTab(1);
                            }}
                          >
                            <span className="number">1.</span>{" "}
                            Personal Information
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 2 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              setactiveTab(2);
                            }}
                          >
                            <span className="number">2.</span>{" "}
                            Jobs Application
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 3 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              setactiveTab(3);
                            }}
                          >
                            <span className="number">3.</span>
                            Upload Resume
                          </NavLink>
                        </NavItem>
                        <NavItem className={classnames({ current: activeTab === 4 })}>
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              setactiveTab(4);
                            }}
                          >
                            <span className="number">4.</span>
                            Confirm Detail
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent
                        activeTab={activeTab}
                        className="body"
                      >
                        <TabPane tabId={1}>
                          <Form>

                            <Row>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtFirstNameBilling"
                                    className="col-lg-3 col-form-label">Name</label>
                                  <div className="col-lg-9">
                                    <Input id="txtFirstNameBilling" name="txtFirstNameBilling"
                                      type="text" className="form-control" placeholder="Enter your name"/>
                                  </div>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtLastNameBilling"
                                    className="col-lg-3 col-form-label">Civil Status</label>
                                  <div className="col-lg-9">
                                    <Input id="txtLastNameBilling" name="txtLastNameBilling"
                                      type="text" className="form-control" placeholder="Enter your civil Status"/>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtCompanyBilling"
                                    className="col-lg-3 col-form-label">Age</label>
                                  <div className="col-lg-9">
                                    <Input id="txtCompanyBilling" name="txtCompanyBilling"
                                      type="text" className="form-control" placeholder="Enter your Age"/>
                                  </div>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtEmailAddressBilling"
                                    className="col-lg-3 col-form-label">Mobile No.</label>
                                  <div className="col-lg-9">
                                    <Input id="txtEmailAddressBilling" name="txtEmailAddressBilling"
                                      type="text" className="form-control" placeholder="Enter your mobile number"/>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtCityBilling" className="col-lg-3 col-form-label">Gender</label>
                                  <div className="col-lg-9">
                                    <Input id="txtCityBilling" name="txtCityBilling" type="text"
                                      className="form-control" placeholder="Enter your company gender"/>
                                  </div>
                                </Row>
                              </Col>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtTelephoneBilling"
                                    className="col-lg-3 col-form-label">Email Address</label>
                                  <div className="col-lg-9">
                                    <Input id="txtTelephoneBilling" name="txtTelephoneBilling"
                                      type="text" className="form-control"  placeholder="Enter your email address"/>
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtAddress1Billing"
                                    className="col-lg-3 col-form-label">Address</label>
                                  <div className="col-lg-9">
                                    <textarea id="txtAddress1Billing" name="txtAddress1Billing"
                                      rows="4" className="form-control" placeholder="Enter your address"></textarea>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>

                          <Form>
                            <div className="mb-3">
                              <h4 className="font-size-15"> Introduction</h4>
                                <Editor
                                  toolbarClassName="toolbarClassName"
                                  wrapperClassName="wrapperClassName"
                                  editorClassName="editorClassName"
                                />
                            </div>
                            <Row>
                              <Col md={6}>
                                
                                <Row className="mb-3">
                                  <label htmlFor="ddlCreditCardType"
                                    className="col-lg-3 col-form-label">Position</label>
                                  <div className="col-lg-9">
                                    <Input type="select" id="ddlCreditCardType" name="ddlCreditCardType"
                                      className="form-select">
                                      <option value="">--Please Select--</option>
                                      <option value="AE">IT Manager</option>
                                      <option value="VI">Accounting</option>
                                      <option value="MC">Web Developer</option>
                                    </Input>
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtLastNameShipping"
                                    className="col-lg-3 col-form-label">Work Experience: </label>
                                  <div className="col-lg-9">
                                    <Input id="txtLastNameShipping" name="txtLastNameShipping"
                                      type="text" className="form-control" placeholder="Enter How many years or month" />
                                  </div>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6}>
                                <Row className="mb-3">
                                  <label htmlFor="txtLastNameShipping"
                                    className="col-lg-3 col-form-label">Expected Salary.</label>
                                  <div className="col-lg-9">
                                    <Input id="txtLastNameShipping" name="txtLastNameShipping"
                                      type="text" className="form-control" placeholder="Enter Amount" />
                                  </div>
                                </Row>
                              </Col>
                            </Row>

                          </Form>
                        </TabPane>
                        <TabPane tabId={3}>

                          <Form>
                            <Dropzone
                                    onDrop={acceptedFiles => {
                                    handleAcceptedFiles(acceptedFiles);
                                    }}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone">
                                        <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                        >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="mdi mdi-cloud-upload display-4 text-muted"></i>
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                        </div>
                                    </div>
                                    )}
                                </Dropzone>
                                <div className="dropzone-previews mt-3" id="file-previews">
                                    {selectedFiles.map((f, i) => {
                                    return (
                                        <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                        >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={f.name}
                                                src={f.preview}
                                                />
                                            </Col>
                                            <Col>
                                                <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                                >
                                                {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                            </Row>
                                        </div>
                                        </Card>
                                    );
                                    })}
                                </div>
                            <div className="text-center mt-4">
                                <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                >
                                Upload File
                                </button>
                            </div>
                          </Form>

                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  <i className="mdi mdi-check-circle-outline text-success display-4" />
                                </div>
                                <div>
                                  <h5>Confirm Detail</h5>
                                  <p className="text-muted">
                                    If several languages coalesce, the grammar of
                                    the resulting
                                  </p>
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          {activeTab > 1 &&
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            Previous
                          </Link>
                          }
                        </li>
                        <li
                          // className={activeTab === 4 ? "next disabled" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            {activeTab === 4 ? "Confirm":"Next"}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ApplicantForm;