const date = new Date()
const d = date.getDate()
const m = date.getMonth()
const y = date.getFullYear()

const events = [
  {
    id: 1,
    title: "Holiday",
    start: new Date(y, m, d + 2),
    end: new Date(y, m, d + 2),
    allDay: true,
    className: "bg-warning text-white",
  },
  {
    id: 1,
    title: "Holiday",
    start: new Date(y, m, d + 4),
    end: new Date(y, m, d + 4),
    allDay: true,
    className: "bg-warning text-white",
  },
  {
    id: 1,
    title: "Holiday",
    start: new Date(y, m, d + 5),
    end: new Date(y, m, d + 5),
    allDay: true,
    className: "bg-warning text-white",
  },
  {
    id: 1,
    title: "Holiday",
    start: new Date(y, m, d + 3),
    end: new Date(y, m, d + 3),
    allDay: true,
    className: "bg-warning text-white",
  },
  {
    id: 2,
    title: "Time In",
    start: new Date(y, m, d, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 3,
    title: "Absent",
    start: new Date(y, m, d - 1),
    end: new Date(y, m, d - 1),
    allDay: true,
    className: "bg-danger text-white",
  },
  {
    id: 4,
    title: "Time In",
    start: new Date(y, m, d - 2, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 5,
    title: "Time Out",
    start: new Date(y, m, d - 2, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 6,
    title: "Time Out",
    start: new Date(y, m, d - 3, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 7,
    title: "No Time In",
    start: new Date(y, m, d - 3),
    end: new Date(y, m, d - 3),
    allDay: true,
    className: "bg-secondary text-dark text-info",
  },
  {
    id: 8,
    title: "Time In",
    start: new Date(y, m, d - 4, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 9,
    title: "Time Out",
    start: new Date(y, m, d - 4, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 10,
    title: "Time In",
    start: new Date(y, m, d - 5, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 11,
    title: "Time Out",
    start: new Date(y, m, d - 5, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 12,
    title: "Leave",
    start: new Date(y, m, d - 8),
    allDay: true,
    className: "bg-info text-white",
  },
  {
    id: 13,
    title: "Time In",
    start: new Date(y, m, d - 9, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 14,
    title: "Time Out",
    start: new Date(y, m, d - 9, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 15,
    title: "Time In",
    start: new Date(y, m, d - 10, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 16,
    title: "Time Out",
    start: new Date(y, m, d - 10, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 17,
    title: "Time In",
    start: new Date(y, m, d - 11, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 18,
    title: "Time Out",
    start: new Date(y, m, d - 11, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 19,
    title: "Time In",
    start: new Date(y, m, d - 12, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 11,
    title: "Time Out",
    start: new Date(y, m, d - 12, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 10,
    title: "Time In",
    start: new Date(y, m, d - 13, 9, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 11,
    title: "Time Out",
    start: new Date(y, m, d - 13, 19, 30),
    allDay: false,
    className: "bg-success text-white",
  },
]

const calenderDefaultCategories = [
  {
    id: 1,
    title: "New Event Planning",
    type: "bg-success",
  },
  {
    id: 2,
    title: "Meeting",
    type: "bg-info",
  },
  {
    id: 3,
    title: "Generating Reports",
    type: "bg-warning",
  },
  {
    id: 4,
    title: "Create New theme",
    type: "bg-danger",
  },
]

const eventsBak = [
  {
    id: 1,
    title: "Long Event",
    start: new Date(y, m, d - 5),
    end: new Date(y, m, d - 2),
    className: "bg-warning text-white",
  },
  {
    id: 2,
    title: "Repeating Event",
    start: new Date(y, m, d - 3, 16, 0),
    allDay: false,
    className: "bg-info text-white",
  },
  {
    id: 3,
    title: "Repeating Event",
    start: new Date(y, m, d + 4, 16, 0),
    allDay: false,
    className: "bg-primary text-white",
  },
  {
    id: 4,
    title: "Meeting",
    start: new Date(y, m, d, 10, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 5,
    title: "Lunch",
    start: new Date(y, m, d, 12, 0),
    end: new Date(y, m, d, 14, 0),
    allDay: false,
    className: "bg-danger text-white",
  },
  {
    id: 6,
    title: "Birthday Party",
    start: new Date(y, m, d + 1, 19, 0),
    end: new Date(y, m, d + 1, 22, 30),
    allDay: false,
    className: "bg-success text-white",
  },
  {
    id: 7,
    title: "Click for Google",
    start: new Date(y, m, 28),
    end: new Date(y, m, 29),
    url: "http://google.com/",
    className: "bg-dark text-white",
  },
  {
    id: 8,
    title: "All Day Event",
    start: new Date(y, m, 1),
    className: "text-white",
  },
  {
    id: 9,
    title: "Time In",
    start: new Date(y, m, d, 19, 0),
    className: "text-white",
  },
]

export { calenderDefaultCategories, events }
