import React, { useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  InputGroup,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import Select from "react-select"
import { Link } from "react-router-dom"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { selectEmployee } from "constants/data"
import styled from "styled-components"
import Flatpickr from "react-flatpickr"

const SearchView = styled.div`
  .filter-item {
    width: 250px;
    margin-right: 10px;

    .InputView {
      height: 37px;
    }
  }
`

const optionGroup = [
  {
    label: "Employee",
    options: [
      { label: "Jay", value: "Jay" },
      { label: "Aljon", value: "Aljon" },
      { label: "Kalil", value: "Kalil" },
      { label: "Brent", value: "Brent" },
    ],
  },
]

const Leaves = () => {
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date From",
        field: "dateFrom",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date To",
        field: "dateTo",
        sort: "asc",
        width: 150,
      },
      {
        label: "AM/PM",
        field: "ampm",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "leaveType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        name: "Jose Rizal",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "Whole Day",
        leaveType: "Vacation Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Aljon Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "AM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Jay Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "PM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
    ],
  }

  const [modal_center, setmodal_center] = useState(false)
  const [modal_Edit_Leave, set_modal_Edit_Leave] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const togEditLeave = () => {
    set_modal_Edit_Leave(!modal_Edit_Leave)
    removeBodyCss()
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  document.title = "Data Tables | ByteFlare - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="ByteFlare"
            title="DTR"
            breadcrumbItem="Leaves"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">List of Leaves </CardTitle>

                  <div className="d-flex justify-content-between">
                    <SearchView className="d-flex justify-content-start mb-4">
                      <FormGroup className="filter-item">
                        <Label>Select Employee</Label>
                        <Select
                          className="InputView"
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti()
                          }}
                          options={selectEmployee}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      <FormGroup className="filter-item">
                        <Label>Date Range</Label>
                        <InputGroup>
                          <Flatpickr
                            className="InputView form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="filter-item position-relative">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light position-absolute bottom-0"
                          onClick={tog_center}
                        >
                          <i className="fas fa-search"></i> Search
                        </Button>
                      </FormGroup>
                    </SearchView>
                    <div>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={tog_center}
                      >
                        <i className="fas fa-plus"></i> Create Leave
                      </Button>
                    </div>
                  </div>
                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Create Leaves
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Select Employee
                        </label>
                        <div className="col-md-10">
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Leave type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Sick Leave</option>
                            <option>Vacation Leave</option>
                            <option>Maternity Leave</option>
                            <option>Faternity Leave</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date From
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date To
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Reason
                        </label>
                        <div className="col-md-10">
                          <textarea
                            id="txtAddress2Billing"
                            name="txtAddress2Billing"
                            rows="4"
                            className="form-control"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Upload
                        </label>
                        <div className="col-md-10">
                          <input type="file" className="form-control" />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  <Modal
                    isOpen={modal_Edit_Leave}
                    toggle={togEditLeave}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={togEditLeave}>
                      Edit Leaves
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Status
                        </label>
                        <div
                          className="col-md-10"
                          style={{ paddingTop: "7px" }}
                        >
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline1"
                              name="customRadioInline1"
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline1"
                            >
                              <span className="badge bg-success">Approved</span>
                            </Label>
                          </div>

                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline2"
                            >
                              <span className="badge bg-danger">Declined</span>
                            </Label>
                          </div>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Employee
                        </label>
                        <div className="col-md-10">
                          <Select
                            value={selectedGroup}
                            onChange={() => {
                              handleSelectGroup()
                            }}
                            options={optionGroup}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Leave type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Sick Leave</option>
                            <option>Vacation Leave</option>
                            <option>Maternity Leave</option>
                            <option>Faternity Leave</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date From
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date To
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Reason
                        </label>
                        <div className="col-md-10">
                          <textarea
                            id="txtAddress2Billing"
                            name="txtAddress2Billing"
                            rows="4"
                            className="form-control"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Upload
                        </label>
                        <div className="col-md-10">
                          <input type="file" className="form-control" />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={togEditLeave}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={togEditLeave}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>
                  {/* <MDBDataTable responsive striped bordered data={data} /> */}
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Leave Type</th>
                          <th scope="col">Date From</th>
                          <th scope="col">Date To</th>
                          <th scope="col" colSpan="2">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Sick Leave</td>
                          <td>15/1/2018</td>
                          <td>15/1/2018</td>
                          <td>
                            <span className="badge bg-success">Approved</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14257</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Brent Shipley
                            </div>
                          </td>
                          <td>Sick Leave</td>
                          <td>16/1/2019</td>
                          <td>16/1/2019</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14258</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Robert Sitton
                            </div>
                          </td>
                          <td>Sick Leave</td>
                          <td>17/1/2019</td>
                          <td>17/1/2019</td>
                          <td>
                            <span className="badge bg-success">Approved</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14259</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alberto Jackson
                            </div>
                          </td>
                          <td>Vacation Leave</td>
                          <td>18/1/2019</td>
                          <td>18/1/2019</td>
                          <td>
                            <span className="badge bg-danger">Declined</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14260</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              David Sanchez
                            </div>
                          </td>
                          <td>Maternity Leave</td>
                          <td>19/1/2019</td>
                          <td>19/1/2019</td>
                          <td>
                            <span className="badge bg-success">Approved</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14261</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>Sick Leave</td>
                          <td>15/1/2018</td>
                          <td>15/1/2018</td>
                          <td>
                            <span className="badge bg-warning">Pending</span>
                          </td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Leaves
