import React, { useState } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import Pie from "../AllCharts/echart/piechart";
import Line from "../AllCharts/echart/linechart";
import Select from "react-select";



import "chartist/dist/scss/chartist.scss"

import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"

import { DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const AssetsList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [selectedGroup, setselectedGroup] = useState(null);

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  const optionGroup = [
    {
      label: "Year",
      options: [
        { label: "2019", value: "2019" },
        { label: "2020", value: "2020" },
        { label: "2021", value: "2021" },
        { label: "2022", value: "2022" },
        { label: "2023", value: "2023" },
        { label: "All time", value: "All Time" },
      ]
    }
  ];


  document.title = "Assets | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <hr />
                  <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Computers
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      127{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-info text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Available Headsets
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      31{" "}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-danger text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Occupied Computers
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      72{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-info">
                      <p className="mb-0"> 10%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-success text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Total Costs
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      ₱2,436,000{" "}
                      <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last year</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
                <Card>
                  <CardBody>
                    <h4 className="mt-0 header-title mb-4">Assets Chart</h4>
                    <div id="pie-chart" className="e-chart">
                      <Pie />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title mb-4">Asset Expenses</h4>
                  <div id="line-chart" className="e-chart">
                    <Line />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <h2>Assets</h2>
            <Col xs={2}>
              <div class="mt-2">
                <Select
                  value={selectedGroup}
                  onChange={() => {
                    handleSelectGroup();
                  }}
                  placeholder="Year"
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </div>
            </Col>
            <Col/>
            <Col lg={2}>
              <div class>
                <Button
                  color="primary"
                  className="btn btn-primary mt-2 waves-effect waves-light"
                  onClick={toggleAdd}
                >
                  <i class="fas fa-plus"></i> Add Asset
                </Button>
                <Button
                  color="primary"
                  className="btn btn-primary ms-2 mt-2 waves-effect waves-light"
                >
                  <i class="fas fa-print"></i> Export
                </Button>
              </div>
            </Col>
          </Row>
          <hr />
          <div className="table-responsive">
            <Table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th>Asset Name</th>
                  <th>Category</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total Expense</th>
                  <th>Available</th>
                  <th>Occupied</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dell Laptop</td>
                  <td>Laptop</td>
                  <td>24</td>
                  <td>₱ 55,121</td>
                  <td>₱ 216,215</td>
                  <td>13</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>Mac Mini</td>
                  <td>Computer</td>
                  <td>32</td>
                  <td>₱ 50,115</td>
                  <td>₱ 246,927</td>
                  <td>12</td>
                  <td>20</td>
                </tr>
                <tr>
                  <td>Logitech Pro</td>
                  <td>Headset</td>
                  <td>57</td>
                  <td>₱ 8,851</td>
                  <td>₱ 92,992</td>
                  <td>10</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>Hp Mouse</td>
                  <td>Mouse</td>
                  <td>80</td>
                  <td>₱ 800</td>
                  <td>₱ 53,992</td>
                  <td>45</td>
                  <td>35</td>
                </tr>
              </tbody>
            </Table>
          </div>
          </CardBody>
              </Card>
            </Col>
          </Row>
          {/* VIEW MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalView} toggle={toggleView} size="lg">
                <ModalHeader className="mt-0" toggle={toggleView}>
                  View Assets
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asset
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Asset Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Date
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Date"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Select Status"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Asset
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Item Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Item Name"
                        />
                      </div>
                    </Row>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </label>
                      <div className="col-md-10">    
                          <select className="form-control">
                              <option>Laptop</option>
                              <option>Desktop</option>
                              <option>Peripherals</option>
                              <option>Printer</option>
                              <option>Mobile Phone</option>
                            </select>
                      </div>
                    </Row>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Serial Number
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Asset Serial"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Purchase Date
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          placeholder="Enter Date"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Purchase Price
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Enter Price"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Description</label>
                      <div className="col-md-10">
                        <textarea
                          id="txtAddress2Billing"
                          name="txtAddress2Billing"
                          rows="4"
                          className="form-control"
                        />
                      </div>
                    </Row>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={toggleAdd}>
                          Save
                        </button>
                        <button type="button" className="btn btn-danger" onClick={toggleAdd}>
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Asset
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Assets
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Asset Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Assets Head
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Date"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Select Status"
                        />
                      </div>
                    </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssetsList
