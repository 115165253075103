import React, { useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  FormGroup,
  Label,
  InputGroup,
  Input,
} from "reactstrap"

import Flatpickr from "react-flatpickr"
import styled from "styled-components"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import Select from "react-select"
import { Link } from "react-router-dom"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { selectEmployee } from "constants/data"

const UserSchedule = styled.div`
  cursor: pointer;
  border: 2px dashed rgb(98 110 212);
  display: grid;
  padding: 5px 7px;

  .userrole-info {
    text-align: center;
  }
`
const AddUserSchedule = styled.div`
  cursor: pointer;
  border: 2px dashed #978686;
  padding: 5px 7px;
  width: 30px;
  height: auto;
  margin-right: 146px;
`
const SelectShift = styled.select`
  width: 120px;
  text-align: center;
`
const SearchView = styled.div`
  .filter-item {
    width: 250px;
    margin-right: 10px;

    .InputView {
      height: 37px;
    }
  }
`

const Scheduler = () => {
  const [modal_center, setmodal_center] = useState(false)
  const [modal_EditShift, semodal_EditShift] = useState(false)
  const [modal_SelShift, setmodal_SelShift] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const tog_EditShift = () => {
    semodal_EditShift(!modal_EditShift)
    removeBodyCss()
  }

  const tog_SelShift = () => {
    setmodal_SelShift(!modal_SelShift)
    removeBodyCss()
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  document.title = "Data Tables | ByteFlare - React Admin & Dashboard Template"

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="ByteFlare"
            title="DTR"
            breadcrumbItem="Scheduler"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">List of Scheduler</CardTitle>
                  <div className="d-flex justify-content-between">
                    <SearchView className="d-flex justify-content-start mb-4">
                      <FormGroup className="filter-item">
                        <Label>Select Employee</Label>
                        <Select
                          className="InputView"
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti()
                          }}
                          options={selectEmployee}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      <FormGroup className="filter-item">
                        <Label>Date Range</Label>
                        <InputGroup>
                          <Flatpickr
                            className="InputView form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="filter-item position-relative">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light position-absolute bottom-0"
                        >
                          <i className="fas fa-search"></i> Search
                        </Button>
                      </FormGroup>
                    </SearchView>
                    <div>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={tog_center}
                      >
                        <i className="fas fa-plus"></i> Add Shifts
                      </Button>
                    </div>
                  </div>

                  <div className="button-items">
                    <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light"
                      data-toggle="button"
                      aria-pressed="false"
                      onClick={tog_SelShift}
                    >
                      Assign Selected
                    </Button>
                  </div>

                  <Modal
                    isOpen={modal_SelShift}
                    toggle={tog_SelShift}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_SelShift}>
                      Assign Shift to Selected Employee
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Range
                        </label>
                        <div className="col-md-10">
                          <InputGroup>
                            <Flatpickr
                              className="InputView form-control d-block"
                              placeholder="DD/MM/YYYY"
                              options={{
                                mode: "range",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Shift Name
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Select Shift</option>
                            <option>Day Shift</option>
                            <option>Mid Shift</option>
                            <option>Night Shift</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Time Start
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="time"
                            id="example-time-input"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Time End
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="time"
                            id="example-time-input"
                          />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_SelShift}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={tog_SelShift}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  <Modal
                    isOpen={modal_EditShift}
                    toggle={tog_EditShift}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_EditShift}>
                      March 1, 2023 Shifts
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Employee Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            value={"Jhon Doe"}
                            placeholder="Employee Name"
                            disabled
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Shift Name
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Select Shift</option>
                            <option>Day Shift</option>
                            <option>Mid Shift</option>
                            <option>Night Shift</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Time Start
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="time"
                            id="example-time-input"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Time End
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="time"
                            id="example-time-input"
                          />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_EditShift}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={tog_EditShift}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Add Shifts
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Shift Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Shift Name"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Time Start
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="time"
                            id="example-time-input"
                          />
                        </div>
                      </Row>

                      <Row className="mb-3">
                        <label
                          htmlFor="example-time-input"
                          className="col-md-2 col-form-label"
                        >
                          Time End
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="time"
                            id="example-time-input"
                          />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div className="form-check">
                              <Input
                                type="checkbox"
                                className="form-check-input"
                                id="invalidCheck"
                              />
                              <Label className="form-check-label mb-0">
                                {" "}
                                Select All
                              </Label>
                            </div>
                          </th>
                          <th scope="col">Employee</th>
                          <th scope="col">Wed 1</th>
                          <th scope="col">Thu 2</th>
                          <th scope="col">Fri 3</th>
                          <th scope="col">Sat 4</th>
                          <th scope="col">Sun 5</th>
                          <th scope="col">Mon 6</th>
                          <th scope="col">Tue 7</th>
                          <th scope="col">Wed 8</th>
                          <th scope="col">Thu 9</th>
                          <th scope="col">Fri 10</th>
                          <th scope="col">Sat 11</th>
                          <th scope="col">Sun 12</th>
                          <th scope="col">Mon 13</th>
                          <th scope="col">Tue 14</th>
                          <th scope="col">Wed 15</th>
                          <th scope="col">Thu 16</th>
                          <th scope="col">Fri 17</th>
                          <th scope="col">Sat 18</th>
                          <th scope="col">Sun 19</th>
                          <th scope="col">Mon 20</th>
                          <th scope="col">Tue 21</th>
                          <th scope="col">Wed 22</th>
                          <th scope="col">Thu 23</th>
                          <th scope="col">Fri 24</th>
                          <th scope="col">Sat 25</th>
                          <th scope="col">Sun 26</th>
                          <th scope="col">Mon 27</th>
                          <th scope="col">Tue 28</th>
                          <th scope="col">Wed 29</th>
                          <th scope="col">Thu 30</th>
                          <th scope="col">Fri 31</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jhon Doe
                            </div>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Antonio Luna
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jose Rizal
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jose Rizal
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jose Rizal
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jose Rizal
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jose Rizal
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="invalidCheck"
                            />{" "}
                            #14256
                          </th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jose Rizal
                            </div>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <UserSchedule onClick={tog_EditShift}>
                              <span className="username-info m-b-10">
                                6:30 am - 9:30 pm ( 14 hrs 15 mins)
                              </span>
                              <span className="userrole-info">Day Shift</span>
                            </UserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                          <td>
                            <AddUserSchedule onClick={tog_EditShift}>
                              <i className="fa fa-plus"></i>
                            </AddUserSchedule>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Scheduler
