import React, { useState } from "react"
import { Link } from "react-router-dom"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  CardSubtitle,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import Select from "react-select";

import * as Yup from "yup"
import { useFormik } from "formik"

import { DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"

const EmployeeList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [singlebtn, setSinglebtn] = useState(false)
  const [genderbtn, setGenderbtn] = useState(false)
  const [filter, setFilter] = useState("Year")
  const [gender, setGender] = useState("Male")
  const [drp_secondary, setDrp_secondary] = useState(false)
  const [selectedMulti, setselectedMulti] = useState(null);

  function handleSubmit(e) {
    e.preventDefault()

    var fnm = document.getElementById("validationTooltip01").value
    var lnm = document.getElementById("validationTooltip02").value
    var unm = document.getElementById("validationTooltipUsername").value
    var city = document.getElementById("validationTooltip03").value
    var stateV = document.getElementById("validationTooltip04").value

    if (fnm === "") {
      setfnm(false)
    } else {
      setfnm(true)
    }

    if (lnm === "") {
      setlnm(false)
    } else {
      setlnm(true)
    }

    if (unm === "") {
      setunm(false)
    } else {
      setunm(true)
    }

    if (city === "") {
      setcity(false)
    } else {
      setcity(true)
    }

    if (stateV === "") {
      setstateV(false)
    } else {
      setstateV(true)
    }

    var d1 = document.getElementsByName("validate")

    document.getElementById("tooltipForm").classList.add("was-validated")

    for (var i = 0; i < d1.length; i++) {
      d1[i].style.display = "block"
    }
  }

  //for change tooltip display propery
  function changeHandeler(event, eleId) {
    if (event.target.value !== "")
      document.getElementById(eleId).style.display = "none"
    else document.getElementById(eleId).style.display = "block"
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "Mark",
      lastname: "Otto",
      city: "City",
      state: "State",
      zip: "Zip",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      city: Yup.string().required("Please Enter Your City"),
      state: Yup.string().required("Please Enter Your State"),
      zip: Yup.string().required("Please Enter Your Zip"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })

  // Form validation
  const validationType = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "",
      password: "",
      password1: "",
      email: "",
      digits: "",
      number: "",
      alphanumeric: "",
      textarea: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("This value is required"),
      password: Yup.string().required("This value is required"),
      password1: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
      email: Yup.string()
        .email("Must be a valid Email")
        .max(255)
        .required("Email is required"),
      url: Yup.string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .required("Please enter correct Url"),
      digits: Yup.number().required("Please Enter Your Digits"),
      number: Yup.number().required("Please Enter Your Number"),
      alphanumeric: Yup.string()
        .matches(/^[a-z0-9]+$/i, "Enter correct Alphanumeric!")
        .required("Please Enter Your Alphanumeric"),
      textarea: Yup.string().required("Please Enter Your Textarea"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })
  const regExp = /\b\d{5}\b/
  // Form validation
  const rangeValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      min_Length: "",
      max_Length: "",
      range_Length: "",
      min_Value: "",
      max_Value: "",
      range_Value: "",
      regular_Exp: "",
    },
    validationSchema: Yup.object().shape({
      min_Length: Yup.string()
        .min(6, "Must be exactly 6 digits")
        .required("Min 6 chars"),
      max_Length: Yup.string()
        .max(6, "Must be exactly 6 digits")
        .required("Max 6 chars"),
      range_Length: Yup.string()
        .required("range between 5 to 10")
        .min(5, "This value should be between 5 and 10")
        .max(10, "This value should be between 5 and 10"),
      min_Value: Yup.string()
        .required("Min Value 6")
        .test(
          "val",
          "This value should be greater than or equal to 6",
          val => val >= 6
        ),
      max_Value: Yup.string()
        .required("Max Value 6")
        .matches(/^[0-6]+$/, "This value should be lower than or equal to 6."),
      range_Value: Yup.string()
        .required("range between 5 to 10")
        .min(5, "This value should be between 5 and 10")
        .max(10, "This value should be between 5 and 10"),
      regular_Exp: Yup.string()
        .matches(/^[#0-9]+$/, "Only Hex Value")
        .required("Only Hex Value"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })

  const optionGroup = [
    {
      label: "Picnic",
      options: [
        { label: "Email", value: "Email" },
        { label: "Contact", value: "Contact" },
        { label: "Department", value: "Department" },
        { label: "Birth Date", value: "Birth Date" },
        { label: "Civil Status", value: "Civil Status" },
        { label: "Department", value: "Department" },
        { label: "Employement Status", value: "Employement Status" },

      ]
    },
  ];

  const [formValidation, setValidation] = useState({
    fnm: null,
    lnm: null,
    unm: null,
    city: null,
    stateV: null,
  })

  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  function handleSubmit(e) {
    e.preventDefault()
    const modifiedV = { ...formValidation }
    var fnm = document.getElementById("validationTooltip01").value
    var lnm = document.getElementById("validationTooltip02").value
    var unm = document.getElementById("validationTooltipUsername").value
    var city = document.getElementById("validationTooltip03").value
    var stateV = document.getElementById("validationTooltip04").value

    if (fnm === "") {
      modifiedV["fnm"] = false
    } else {
      modifiedV["fnm"] = true
    }

    if (lnm === "") {
      modifiedV["lnm"] = false
    } else {
      modifiedV["lnm"] = true
    }

    if (unm === "") {
      modifiedV["unm"] = false
    } else {
      modifiedV["unm"] = true
    }

    if (city === "") {
      modifiedV["city"] = false
    } else {
      modifiedV["city"] = true
    }

    if (stateV === "") {
      modifiedV["stateV"] = false
    } else {
      modifiedV["stateV"] = true
    }
    setValidation(modifiedV)
  }

  //for change tooltip display propery
  const onChangeValidation = (fieldName, value) => {
    const modifiedV = { ...validation }
    if (value !== "") {
      modifiedV[fieldName] = true
    } else {
      modifiedV[fieldName] = false
    }
    setValidation(modifiedV)
  }

  document.title = "Employee | Employee 201"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Employee Report</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                    <div className="mb-3 ">
                        <Select
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti();
                          }}
                          placeholder="Columns"
                          options={optionGroup}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    <Col lg={9}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          // onClick={toggleAdd}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="fas fa-print"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                         EXPORT
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee #</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Department</th>
                          <th scope="col">Birth Date</th>
                          <th scope="col">Employment Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">001</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              John Doe
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td>(+63) 997 258 9638</td>
                          <td>Information Technology (IT)</td>
                          <td>01/03/1996</td>
                          <td>Employed</td>

                        </tr>
                        <tr>
                          <th scope="row">002</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Lorem Ipsum
                            </div>
                          </td>

                          <td>loremipsum@gmail.com</td>
                          <td>(+63) 998 185 1589</td>
                          <td>Human Resources (HR)</td>
                          <td>01/03/1996</td>
                          <td>Employed</td>

                        </tr>
                        <tr>
                          <th scope="row">003</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Doe Lorem
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td> (+63) 995 388 9031</td>
                          <td>Finance / Accounting</td>
                          <td>01/03/1996</td>
                          <td>Resigned</td>

                        </tr>
                        <tr>
                          <th scope="row">004</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              John Doe
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td>(+63) 997 258 9638</td>
                          <td>Information Technology (IT)</td>
                          <td>01/03/1996</td>
                          <td>Onboarding</td>

                        </tr>
                        <tr>
                          <th scope="row">005</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Lorem Ipsum
                            </div>
                          </td>

                          <td>loremipsum@gmail.com</td>
                          <td>(+63) 998 185 1589</td>
                          <td>Human Resources (HR)</td>
                          <td>01/03/1996</td>
                          <td>Employed</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>

          {/* ADD MODAL*/}
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} centered size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add"
                    style={{ fontSize: "23px" }}
                  ></i>{" "}
                  Add New Employee
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <Form
                      className="row g-3 needs-validation mt-2"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              First name
                            </Label>
                            <Input
                              name="firstname"
                              placeholder="Mark"
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.firstname || ""}
                              invalid={
                                validation.touched.firstname &&
                                validation.errors.firstname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.firstname &&
                            validation.errors.firstname ? (
                              <FormFeedback type="invalid">
                                {validation.errors.firstname}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Middle name
                            </Label>
                            <Input
                              name="middlename"
                              placeholder="Otto"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.middlename || ""}
                              invalid={
                                validation.touched.middlename &&
                                validation.errors.middlename
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.lastname &&
                            validation.errors.lastname ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastname}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Last name
                            </Label>
                            <Input
                              name="lastname"
                              placeholder="Otto"
                              type="text"
                              className="form-control"
                              id="validationCustom02"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lastname || ""}
                              invalid={
                                validation.touched.lastname &&
                                validation.errors.lastname
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.lastname &&
                            validation.errors.lastname ? (
                              <FormFeedback type="invalid">
                                {validation.errors.lastname}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col md="9">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">Address</Label>
                            <Input
                              name="city"
                              placeholder="City"
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Address || ""}
                              invalid={
                                validation.touched.city &&
                                validation.errors.city
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom05">Zip Code</Label>
                            <Input
                              name="zip"
                              placeholder="Zip"
                              type="text"
                              className="form-control"
                              id="validationCustom05"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.zip || ""}
                              invalid={
                                validation.touched.zip && validation.errors.zip
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.zip && validation.errors.zip ? (
                              <FormFeedback type="invalid">
                                {validation.errors.zip}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">Gender</Label>
                            <Input
                              name="state"
                              placeholder="Gender"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Gender || ""}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Contact No.
                            </Label>
                            <Input
                              name="city"
                              placeholder="Contact No."
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.contact || ""}
                              invalid={
                                validation.touched.Department &&
                                validation.errors.Department
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Date of Birth
                            </Label>
                            <Input
                              name="state"
                              placeholder="Date of Birth"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.dateofbirth || ""}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="3">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Place of Birth
                            </Label>
                            <Input
                              name="state"
                              placeholder="Place of Birth"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.placeofbirth || ""}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Department
                            </Label>
                            <Input
                              name="city"
                              placeholder="Department"
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Department || ""}
                              invalid={
                                validation.touched.Department &&
                                validation.errors.Department
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              Designation
                            </Label>
                            <Input
                              name="state"
                              placeholder="Designation"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Designation || ""}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col md="4">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">Role</Label>
                            <Input
                              name="state"
                              placeholder="Role"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.Role || ""}
                              invalid={
                                validation.touched.state &&
                                validation.errors.state
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="7">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom03">
                              Emergency Contact Person
                            </Label>
                            <Input
                              name="city"
                              placeholder="Emergency Contact Person"
                              type="text"
                              className="form-control"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.ecp || ""}
                              invalid={
                                validation.touched.ecp && validation.errors.ecp
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.city &&
                            validation.errors.city ? (
                              <FormFeedback type="invalid">
                                {validation.errors.city}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>

                        <Col md="5">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom04">
                              {" "}
                              Emergency Contact No.
                            </Label>
                            <Input
                              name="state"
                              placeholder="Emergency Contact No"
                              type="text"
                              className="form-control"
                              id="validationCustom04"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.ecn || ""}
                              invalid={
                                validation.touched.ecn && validation.errors.ecn
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.state &&
                            validation.errors.state ? (
                              <FormFeedback type="invalid">
                                {validation.errors.state}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>

                      <div className="col-12">
                        <button className="btn btn-primary" type="submit">
                          Submit form
                        </button>
                      </div>
                    </Form>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Employee
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Employee No.
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Employee No."
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Biometrics ID
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Biometrics ID"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Last Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Last Name"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          First Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test First Name"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Middle Name
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Middle Name"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Suffix
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Suffix Name"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Gender
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Gender"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Company
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Test Company"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          Position
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Test Position"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Department
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Department"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Rank
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Rank"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Status"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          SSS
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test SSS"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          TIN
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test TIN"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Pagibig MID No
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Pagibig MID No"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Phil Health No
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Phil Health No"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Start
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Date Start"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-email-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Separated
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Test Date Separated"
                          />
                        </div>
                      </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to Archive this Employee?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Archive
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmployeeList
