import React, { useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  InputGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import Select from "react-select"
import { Link } from "react-router-dom"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { selectEmployee } from "constants/data"
import styled from "styled-components"
import Flatpickr from "react-flatpickr"

const SearchView = styled.div`
  .filter-item {
    width: 250px;
    margin-right: 10px;

    .InputView {
      height: 37px;
    }
  }
`

const optionGroup = [
  {
    label: "Employee",
    options: [
      { label: "Jay", value: "Jay" },
      { label: "Aljon", value: "Aljon" },
      { label: "Kalil", value: "Kalil" },
      { label: "Brent", value: "Brent" },
    ],
  },
]

const DtrSummary = () => {
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date From",
        field: "dateFrom",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date To",
        field: "dateTo",
        sort: "asc",
        width: 150,
      },
      {
        label: "AM/PM",
        field: "ampm",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "leaveType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        name: "Jose Rizal",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "Whole Day",
        leaveType: "Vacation Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Aljon Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "AM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Jay Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "PM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
    ],
  }

  const [modal_center, setmodal_center] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  document.title = "Data Tables | ByteFlare - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="ByteFlare"
            title="DTR"
            breadcrumbItem="DTR Summary"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">DTR Summary </CardTitle>

                  <div className="d-flex justify-content-between">
                    <SearchView className="d-flex justify-content-start mb-4">
                      <FormGroup className="filter-item">
                        <Label>Select Employee</Label>
                        <Select
                          className="InputView"
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti()
                          }}
                          options={selectEmployee}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      <FormGroup className="filter-item">
                        <Label>Date Range</Label>
                        <InputGroup>
                          <Flatpickr
                            className="InputView form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="filter-item position-relative">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light position-absolute bottom-0"
                          onClick={tog_center}
                        >
                          <i className="fas fa-search"></i> Search
                        </Button>
                      </FormGroup>
                    </SearchView>
                  </div>

                  {/* <MDBDataTable responsive striped bordered data={data} /> */}
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Attendance</th>
                          <th scope="col">Absences</th>
                          <th scope="col">Lates</th>
                          <th scope="col">Leaves</th>
                          <th scope="col" colSpan="2">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>20 days</td>
                          <td>2 days</td>
                          <td>5 mins</td>
                          <td>-</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="/AttendanceAdmin"
                                className="btn btn-primary btn-sm"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>21 days</td>
                          <td>5 days</td>
                          <td>15 mins</td>
                          <td>1 day</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="/AttendanceAdmin"
                                className="btn btn-primary btn-sm"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>21 days</td>
                          <td>8 days</td>
                          <td>51 mins</td>
                          <td>-</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="/AttendanceAdmin"
                                className="btn btn-primary btn-sm"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>20 days</td>
                          <td>2 days</td>
                          <td>5 mins</td>
                          <td>-</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="/AttendanceAdmin"
                                className="btn btn-primary btn-sm"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>20 days</td>
                          <td>2 days</td>
                          <td>1hour 5 mins</td>
                          <td>-</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="/AttendanceAdmin"
                                className="btn btn-primary btn-sm"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>20 days</td>
                          <td>2 days</td>
                          <td>5 mins</td>
                          <td>-</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="/AttendanceAdmin"
                                className="btn btn-primary btn-sm"
                              >
                                View
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DtrSummary
