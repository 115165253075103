import React, { useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  InputGroup,
  Label,
  FormGroup,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import Select from "react-select"
import { Link } from "react-router-dom"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { selectEmployee } from "constants/data"
import styled from "styled-components"
import Flatpickr from "react-flatpickr"

const optionGroup = [
  {
    label: "Employee",
    options: [
      { label: "Jay", value: "Jay" },
      { label: "Aljon", value: "Aljon" },
      { label: "Kalil", value: "Kalil" },
      { label: "Brent", value: "Brent" },
    ],
  },
]
const SearchView = styled.div`
  .filter-item {
    width: 250px;
    margin-right: 10px;

    .InputView {
      height: 37px;
    }
  }
`
const Attendance = () => {
  const [modal_center, setmodal_center] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  document.title = "Data Tables | ByteFlare - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="ByteFlare"
            title="DTR"
            breadcrumbItem="Attendance"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">List of Attendance </CardTitle>

                  <div className="d-flex justify-content-between">
                    <SearchView className="d-flex justify-content-start mb-4">
                      <FormGroup className="filter-item">
                        <Label>Select Employee</Label>
                        <Select
                          className="InputView"
                          value={selectedMulti}
                          isMulti={true}
                          onChange={() => {
                            handleMulti()
                          }}
                          options={selectEmployee}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                      <FormGroup className="filter-item">
                        <Label>Date Range</Label>
                        <InputGroup>
                          <Flatpickr
                            className="InputView form-control d-block"
                            placeholder="DD/MM/YYYY"
                            options={{
                              mode: "range",
                              dateFormat: "Y-m-d",
                            }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="filter-item position-relative">
                        <Button
                          color="primary"
                          className="btn btn-primary waves-effect waves-light position-absolute bottom-0"
                          onClick={tog_center}
                        >
                          <i className="fas fa-search"></i> Search
                        </Button>
                      </FormGroup>
                    </SearchView>
                    <div>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={tog_center}
                      >
                        <i className="fas fa-plus"></i> Upload DTR
                      </Button>
                    </div>
                  </div>

                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Upload DTR
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Upload File
                        </label>
                        <div className="col-md-10">
                          <input type="file" className="form-control" />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">1</th>
                          <th scope="col">2</th>
                          <th scope="col">3</th>
                          <th scope="col">4</th>
                          <th scope="col">5</th>
                          <th scope="col">6</th>
                          <th scope="col">7</th>
                          <th scope="col">8</th>
                          <th scope="col">9</th>
                          <th scope="col">10</th>
                          <th scope="col">11</th>
                          <th scope="col">12</th>
                          <th scope="col">13</th>
                          <th scope="col">14</th>
                          <th scope="col">15</th>
                          <th scope="col">16</th>
                          <th scope="col">17</th>
                          <th scope="col">18</th>
                          <th scope="col">19</th>
                          <th scope="col">20</th>
                          <th scope="col">21</th>
                          <th scope="col">22</th>
                          <th scope="col">23</th>
                          <th scope="col">24</th>
                          <th scope="col">25</th>
                          <th scope="col">26</th>
                          <th scope="col">27</th>
                          <th scope="col">28</th>
                          <th scope="col">29</th>
                          <th scope="col">30</th>
                          <th scope="col">31</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">#14256</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Philip Smead
                            </div>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-window-close text-danger"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                          <td>
                            <i className="fa fa-check text-success"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Attendance
