import React, { useState, Component } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"

const EmpLeaves = () => {
  const [modal_center, setmodal_center] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const [drp_secondary, setDrp_secondary] = useState(false)

  document.title = "Employee | Leaves"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs maintitle="HRIS" title="MAIN" breadcrumbItem="Leaves" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Employee Leave</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <FilterTxt
                        name="search"
                        type="text"
                        className="form-control w-100 h-100"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={5}>
                      <div className="btn-group mt-1">
                        <ButtonDropdown
                          isOpen={drp_secondary}
                          toggle={() => setDrp_secondary(!drp_secondary)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-outline-info"
                          >
                            <i
                              className="typcn typcn-th-list"
                              style={{ fontSize: "18px" }}
                            />{" "}
                            Select Status <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>Approved</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Pending</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Declined</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={tog_center}
                          size="md"
                          color="primary"
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="typcn typcn-folder-add"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                          Create Leave
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* Create Leave Modal */}
                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Create Leave
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Leave type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Sick Leave</option>
                            <option>Vacation Leave</option>
                            <option>Maternity Leave</option>
                            <option>Paternity Leave</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date From
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date To
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Reason
                        </label>
                        <div className="col-md-10">
                          <textarea
                            id="txtAddress2Billing"
                            name="txtAddress2Billing"
                            rows="4"
                            className="form-control"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Upload
                        </label>
                        <div className="col-md-10">
                          <input type="file" className="form-control" />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Leave type</th>
                          <th scope="col">Date from</th>
                          <th scope="col">Date to</th>
                          <th scope="col">No. Of Days</th>
                          <th scope="col">Reason</th>
                          <th scope="col">Status</th>
                          <th scope="col">Approved By</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sick Leave</td>
                          <td>1/15/2023</td>
                          <td>1/16/2023</td>
                          <td>1</td>
                          <td>Going to Hospital</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Approved
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Doe Lorem
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Maternity Leave</td>
                          <td>1/10/2023</td>
                          <td>1/16/2023</td>
                          <td>6</td>
                          <td>Going to Hospital</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Declined
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Vacation Leave</td>
                          <td>1/10/2023</td>
                          <td>1/18/2023</td>
                          <td>8</td>
                          <td>Going to Boracay</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "60px" }}
                            >
                              Pending
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Sick Leave</td>
                          <td>1/15/2023</td>
                          <td>1/16/2023</td>
                          <td>1</td>
                          <td>Going to Hospital</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Approved
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Doe Lorem
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Maternity Leave</td>
                          <td>1/10/2023</td>
                          <td>1/16/2023</td>
                          <td>6</td>
                          <td>Going to Hospital</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Declined
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Vacation Leave</td>
                          <td>1/10/2023</td>
                          <td>1/18/2023</td>
                          <td>8</td>
                          <td>Going to Boracay</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "60px" }}
                            >
                              Pending
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Sick Leave</td>
                          <td>1/15/2023</td>
                          <td>1/16/2023</td>
                          <td>1</td>
                          <td>Going to Hospital</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Approved
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Doe Lorem
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Maternity Leave</td>
                          <td>1/10/2023</td>
                          <td>1/16/2023</td>
                          <td>6</td>
                          <td>Going to Hospital</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Declined
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Vacation Leave</td>
                          <td>1/10/2023</td>
                          <td>1/18/2023</td>
                          <td>8</td>
                          <td>Going to Boracay</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "60px" }}
                            >
                              Pending
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Vacation Leave</td>
                          <td>1/10/2023</td>
                          <td>1/18/2023</td>
                          <td>8</td>
                          <td>Going to Boracay</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "60px" }}
                            >
                              Pending
                            </span>
                          </td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmpLeaves
