import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  FormGroup,
  InputGroup,
} from "reactstrap";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Select from "react-select"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  addNewEvent,
  deleteEvent,
  getCategories,
  getEvents,
  updateEvent,
} from "../../store/actions";
//css
import "@fullcalendar/bootstrap/main.css";
import moment from "moment";import { selectEmployee } from "constants/data";
import styled from "styled-components";

const SearchView = styled.div`
    .filter-item {
      width: 250px;
      margin-right: 10px;

      .InputView{
        height: 37px;
      }
    }
`

const Birthday = props => {
  const { events, categories } = props;
  const now = new Date();
  const dateString = moment(now).format('MMM d, YYYY');
  const [selectedMulti, setselectedMulti] = useState(null);

  useEffect(() => {
    const { onGetEvents } = props;
    onGetEvents();
  }, []);

  document.title = "Birthday ByteFlare ";
  
const BadgesView = styled.div`
  .my-bg {
    padding: 5px;
    font-size: 12px;
  }

`
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="ByteFlare" breadcrumbItem="Birthday" />
          <Row>
            <Col className="col-12">


              <Row>
                <Col lg={12}>
                  <div className="card">
                    <div className="card-body">
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={"00:15:00"}
                        eventTimeFormat= {{
                          hour: '2-digit',
                          minute: '2-digit',
                          meridiem: true,
                          hour12: true
                        }}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        holidays={events}
                        editable={false}
                        droppable={false}
                        selectable={false}

                      />


                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Birthday.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

const mapStateToProps = ({ calendar }) => ({
  events: calendar.events,
  categories: calendar.categories,
});

const mapDispatchToProps = dispatch => ({
  onGetEvents: () => dispatch(getEvents()),
  onGetCategories: () => dispatch(getCategories()),
  onAddNewEvent: event => dispatch(addNewEvent(event)),
  onUpdateEvent: event => dispatch(updateEvent(event)),
  onDeleteEvent: event => dispatch(deleteEvent(event)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Birthday);
