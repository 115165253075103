import React from "react"
import { Row, Card, CardBody, Container } from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import logoSm from "../../assets/images/ByteFlare_4.png"

const emp_details = [
  {
    title: "Employee ID",
    value: "1159",
  },
  { title: "Gender", value: "male" },
  { title: "Tax Status", value: "S" },
  { title: "Hourly Rate", value: 534.71 },
  { title: "Company Name", value: "Splitmedialabs Philippines Inc." },
  { title: "Date Hired", value: "Aug 16, 2021" },
  { title: "Position", value: "Senior Front-end Developer" },
  { title: "Department", value: "XSplit Apps" },
  { title: "Rank", value: "Rank and file" },
  { title: "Payroll Cycle", value: "Semi-Monthly" },
  { title: "Cost Center", value: "XSplit Apps" },
  { title: "TIN", value: "500-326-110-000" },
  { title: "SSS", value: "34-5235160-5" },
  { title: "HDMF", value: "1211-4719-0530" },
  { title: "Philhealth", value: "02-051140661-8" },
]

const emp_govt = [
  {
    title: "SSS",
    value: "850.00",
  },
  {
    title: "SSS Provident Fund",
    value: "212.50",
  },
  {
    title: "SSS EC",
    value: "0.00",
  },
  {
    title: "HDMF",
    value: "50.00",
  },
  {
    title: "PhilHealth",
    value: "800.00",
  },
  {
    title: "PhilHealth Adjustment",
    value: "0.00",
  },
]

const year_to_date = [
  {
    title: "Gross Income",
    value: "655,276.80",
  },
  {
    title: "Taxable Income",
    value: "576,315.34",
  },
  {
    title: "Withholding Tax",
    value: "113,312.61",
  },
  {
    title: "Net Pay",
    value: "473,124.78",
  },
  {
    title: "Allowance",
    value: "47,343.05",
  },
  {
    title: "Bonus",
    value: "0.00",
  },
  {
    title: "Commission",
    value: " 0.00",
  },
  {
    title: "Deduction",
    value: "184,767.02",
  },
  {
    title: "SSS Employer",
    value: "11,050.00",
  },
  {
    title: "SSS EC Employer",
    value: "210.00",
  },
  {
    title: "PhilHealth Employer",
    value: "6",
  },
  {
    title: "Pag-ibig Employer",
    value: "650.00",
  },
]

const emp_allowance = [
  {
    title: "Clothing Allowance",
    value: "250.00",
  },
  {
    title: "Laundry Allowance",
    value: "150.00",
  },
  {
    title: "Meal Allowance",
    value: "1,271.75",
  },
  {
    title: "Medical Allowance",
    value: "62.50",
  },
  {
    title: "Productivity Incentive Bonus",
    value: "923.93",
  },
  {
    title: "Rice Allowance",
    value: "1,000.00",
  },
]

const emp_deductions = [
  {
    title: "Clothing Allowance",
    value: "(250.00)",
  },
  {
    title: "Laundry Allowance",
    value: "(150.00)",
  },
  {
    title: "Meal Allowance",
    value: "(1,271.75)",
  },
  {
    title: "Medical Allowance",
    value: "(62.50)",
  },
  {
    title: "Productivity Incentive Bonus",
    value: "(923.93)",
  },
  {
    title: "Rice Allowance",
    value: "(1,000.00)",
  },

  {
    title: "SSS",
    value: "(450.00)",
  },
  {
    title: "SSS Provident Fund",
    value: "(112.50)",
  },

  {
    title: "HDMF",
    value: "(50.00)",
  },
  {
    title: "PhilHealth",
    value: "(800.00)",
  },
  {
    title: "PhilHealth Adjustment",
    value: "(0.00)",
  },
]

const EmployeePayslip = () => {
  document.title = "Payslip ByteFlare "
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            maintitle="ByteFlare"
            title="Payroll"
            breadcrumbItem="Payslip"
          />
          <Row>
            <div className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4 className=" mt-0 ">
                          <strong>Payslip</strong>
                        </h4>
                        <h3 className="font-size-16">
                          <img src={logoSm} alt="logo" />
                        </h3>
                      </div>
                      <hr />
                      <Row>
                        <div className="col-4 text-center">
                          <h5>PAY RUN</h5>
                          <h5>Jul 29, 2022</h5>
                        </div>
                        <div className="col-4 text-center">
                          <h5>PAY PERIOD</h5>
                          <h5>Jul 16-Jul 31, 2022</h5>
                        </div>

                        <div className="col-4 text-center">
                          <h5>ATTENDANCE</h5>
                          <h5>Jul 16-Jul 31, 2022</h5>
                        </div>
                      </Row>
                      <hr />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-5">
                      <div className="text-center">
                        <strong>
                          <h5>Blossom Mercader</h5>
                        </strong>
                        <h6>blossom.mercader@byteflare.com.au</h6>
                      </div>
                      <br />
                      {emp_details.map(({ title, value }, i) => (
                        <PaySlipDetail title={title} value={value} />
                      ))}
                      <br />
                      <div className="text-left">
                        <strong>
                          <h5>Employer Contribution</h5>
                        </strong>
                        <hr />
                      </div>

                      {emp_govt.map(({ title, value }, i) => (
                        <PaySlipDetail title={title} value={value} />
                      ))}
                      <br />
                      <div className="text-left">
                        <strong>
                          <h5>Year To Date Figures</h5>
                        </strong>
                        <hr />
                      </div>

                      {year_to_date.map(({ title, value }, i) => (
                        <PaySlipDetail title={title} value={value} />
                      ))}
                    </div>
                    <div className="col-7">
                      <div className="text-left">
                        <strong>
                          <h5>Salary</h5>
                        </strong>
                      </div>
                      <PaySlipDetail
                        title={"Basic Pay"}
                        value={"46,341.83"}
                        extra="92,683.65 ( MONTHLY )"
                      />
                      <div className="text-left">
                        <strong>
                          <h5>Allowances</h5>
                        </strong>
                      </div>

                      {emp_allowance.map(({ title, value }, i) => (
                        <PaySlipDetail title={title} value={value} />
                      ))}
                      <hr />
                      <PaySlipMainDetail
                        title={"GROSS INCOME"}
                        value={"50,000.01"}
                      />
                      <hr />
                      {emp_deductions.map(({ title, value }, i) => (
                        <PaySlipDetail title={title} value={value} />
                      ))}
                      <hr />
                      <PaySlipMainDetail
                        title={"TAXABLE INCOME"}
                        value={"44,929.33"}
                      />
                      <hr />
                      <div className="text-left">
                        <strong>
                          <h5>Tax</h5>
                        </strong>
                      </div>
                      <PaySlipDetail
                        title={"Withholding Tax"}
                        value={"(8,895.57)"}
                      />
                      <div className="text-left">
                        <strong>
                          <h5>Allowance</h5>
                        </strong>
                      </div>
                      {emp_allowance.map(({ title, value }, i) => (
                        <PaySlipDetail title={title} value={value} />
                      ))}
                      <PaySlipDetail
                        title={"Intellicare"}
                        value={"(1,541.49)"}
                      />
                      <hr />
                      <PaySlipMainDetail
                        title={"NET PAY"}
                        value={"38,150.45"}
                      />
                      <hr />
                    </div>

                    <div className="col-12">
                      <div className="d-print-none">
                        <div className="float-end">
                          <Link
                            to="#"
                            onClick={() => {
                              window.print()
                            }}
                            className="btn btn-success waves-effect waves-light"
                          >
                            <i className="fa fa-print"></i>
                          </Link>{" "}
                          <Link
                            to="#"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Send
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const PaySlipDetail = ({ title, value, extra }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <p className="mb-0">{title}</p>
      {extra && <p className="mb-0">{extra}</p>}
      <strong>
        <p className="mb-0">{value}</p>
      </strong>
    </div>
  )
}

const PaySlipMainDetail = ({ title, value, extra }) => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <h5 className="mb-0">{title}</h5>
      {extra && <h5 className="mb-0">{extra}</h5>}
      <strong>
        <h5 className="mb-0">{value}</h5>
      </strong>
    </div>
  )
}

export default EmployeePayslip
