import React, { useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  InputGroup,
  Input,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "../../assets/scss/datatables.scss"
import Select from "react-select"
import { Link } from "react-router-dom"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { selectEmployee } from "constants/data"
import styled from "styled-components"
import Flatpickr from "react-flatpickr"

const SearchView = styled.div`
  .filter-item {
    width: 250px;
    margin-right: 10px;

    .InputView {
      height: 37px;
    }
  }
`

const optionGroup = [
  {
    label: "Employee",
    options: [
      { label: "Jay", value: "Jay" },
      { label: "Aljon", value: "Aljon" },
      { label: "Kalil", value: "Kalil" },
      { label: "Brent", value: "Brent" },
    ],
  },
]

const OpenPosition = () => {
  const data = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date From",
        field: "dateFrom",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date To",
        field: "dateTo",
        sort: "asc",
        width: 150,
      },
      {
        label: "AM/PM",
        field: "ampm",
        sort: "asc",
        width: 150,
      },
      {
        label: "Leave Type",
        field: "leaveType",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "Action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        name: "Jose Rizal",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "Whole Day",
        leaveType: "Vacation Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Aljon Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "AM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
      {
        name: "Jay Nixon",
        dateFrom: "2011/04/25",
        dateTo: "2011/04/25",
        ampm: "PM",
        leaveType: "Sick Leave",
        status: "Pending",
        action: "",
      },
    ],
  }

  const [modal_center, setmodal_center] = useState(false)
  const [modal_Edit_Leave, set_modal_Edit_Leave] = useState(false)
  const [selectedGroup, setselectedGroup] = useState(null)
  const [selectedMulti, setselectedMulti] = useState(null)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const togEditLeave = () => {
    set_modal_Edit_Leave(!modal_Edit_Leave)
    removeBodyCss()
  }

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  document.title = "Data Tables | ByteFlare - React Admin & Dashboard Template"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="ByteFlare"
            title="Jobs"
            breadcrumbItem="Manage Job"
          />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle className="h4">List of Jobs </CardTitle>

                  <div className="d-flex justify-content-between">
                    <SearchView className="d-flex justify-content-start mb-4"></SearchView>
                    <div>
                      <Button
                        color="primary"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={tog_center}
                      >
                        <i className="fas fa-plus"></i> Add Job
                      </Button>
                    </div>
                  </div>
                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Add Job
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Position
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Accounts Payable</option>
                            <option>Web Developer</option>
                            <option>Manager</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Department
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Marketing</option>
                            <option>IT</option>
                            <option>Accounting</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Job type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Full time</option>
                            <option>Part time</option>
                            <option>Internship</option>
                            <option>Temporary</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Start
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Expired
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                      <Button color="primary" onClick={tog_center}>
                        Save changes
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    isOpen={modal_Edit_Leave}
                    toggle={togEditLeave}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={togEditLeave}>
                      Edit Jobs
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Status
                        </label>
                        <div
                          className="col-md-10"
                          style={{ paddingTop: "7px" }}
                        >
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline1"
                              name="customRadioInline1"
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline1"
                            >
                              <span className="badge bg-success">Open</span>
                            </Label>
                          </div>

                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline2"
                            >
                              <span className="badge bg-warning">Hold</span>
                            </Label>
                          </div>
                          <div className="form-check form-check-inline">
                            <Input
                              type="radio"
                              id="customRadioInline2"
                              name="customRadioInline1"
                              className="form-check-input"
                            />
                            <Label
                              className="form-check-label"
                              htmlFor="customRadioInline2"
                            >
                              <span className="badge bg-danger">Cancel</span>
                            </Label>
                          </div>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Position
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Accounts Payable</option>
                            <option>Web Developer</option>
                            <option>Manager</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Department
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Marketing</option>
                            <option>IT</option>
                            <option>Accounting</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Job type
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>Full time</option>
                            <option>Part time</option>
                            <option>Internship</option>
                            <option>Temporary</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Start
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-date-input"
                          className="col-md-2 col-form-label"
                        >
                          Date Expired
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={togEditLeave}>
                        Close
                      </Button>{" "}
                      <Button color="primary" onClick={togEditLeave}>
                        Save changes
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* <MDBDataTable responsive striped bordered data={data} /> */}
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Position</th>
                          <th scope="col">Department</th>
                          <th scope="col">Job Type</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">Expiry Date</th>
                          <th scope="col" colSpan="1">
                            Status
                          </th>
                          <th scope="col">Applicant</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Web Developer</td>
                          <td>IT</td>
                          <td>Part Time</td>
                          <td>15/1/2018</td>
                          <td>15/1/2018</td>
                          <td>
                            <span className="badge bg-success">Open</span>
                          </td>
                          <td>15</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Web Developer</td>
                          <td>IT</td>
                          <td>Internship</td>
                          <td>15/1/2018</td>
                          <td>15/1/2018</td>
                          <td>
                            <span className="badge bg-warning">Hold</span>
                          </td>
                          <td>44</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Web Developer</td>
                          <td>IT</td>
                          <td>Temporary</td>
                          <td>15/1/2018</td>
                          <td>15/1/2018</td>
                          <td>
                            <span className="badge bg-success">Open</span>
                          </td>
                          <td>45</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Web Developer</td>
                          <td>IT</td>
                          <td>Full Time</td>
                          <td>15/1/2018</td>
                          <td>15/1/2018</td>
                          <td>
                            <span className="badge bg-success">Open</span>
                          </td>
                          <td>2</td>
                          <td>
                            <div className="button-items">
                              <Link
                                to="#"
                                className="btn btn-primary btn-sm"
                                onClick={togEditLeave}
                              >
                                Edit
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OpenPosition
