import React, { useState, Component } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"

const PerformanceIndicator = () => {
  const [modal_center, setmodal_center] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const [drp_secondary, setDrp_secondary] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Performance | Performance Indicator"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Performance Indicator"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Performance Indicator</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <FilterTxt
                        name="search"
                        type="text"
                        className="form-control w-100 h-100"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={5}>
                      <div className="btn-group mt-1">
                        <ButtonDropdown
                          isOpen={drp_secondary}
                          toggle={() => setDrp_secondary(!drp_secondary)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-outline-info"
                          >
                            <i
                              className="typcn typcn-th-list"
                              style={{ fontSize: "18px" }}
                            />{" "}
                            Select Deparment{" "}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>CSR</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Information Technology</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Project Management</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={tog_center}
                          size="md"
                          color="primary"
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="typcn typcn-folder-add"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                          Add New
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  {/* ADD MODAL */}
                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Create Performance Indicator
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Designation
                        </label>
                        <div className="col-md-10 mb-2">
                          <select className="form-control">
                            <option>Node JS Developer</option>
                            <option>Android Developer</option>
                            <option>Agent I</option>
                            <option>Project Manager</option>
                          </select>
                        </div>
                      </Row>
                      <hr />
                      <Row className="mb-3 text-center">
                        <Col lg={6}>
                          <h4>Technical</h4>
                        </Col>
                        <Col lg={6}>
                          <h4>Organizational</h4>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Customer Experience
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Integrity
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Marketing
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Professionalism
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Team Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Administration
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Critical Thinking
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Presentation Skill
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Conflict Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Quality Of Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Attendance
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Efficiency
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Ability To Meet Deadline
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Status
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>Active</option>
                              <option>Inactive</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Add
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* EDIT MODAL */}
                  <Modal
                    isOpen={modalEdit}
                    toggle={toggleEdit}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={toggleEdit}>
                      Edit Performance Indicator
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Designation
                        </label>
                        <div className="col-md-10 mb-2">
                          <select className="form-control">
                            <option>Node JS Developer</option>
                            <option>Android Developer</option>
                            <option>Agent I</option>
                            <option>Project Manager</option>
                          </select>
                        </div>
                      </Row>
                      <hr />
                      <Row className="mb-3 text-center">
                        <Col lg={6}>
                          <h4>Technical</h4>
                        </Col>
                        <Col lg={6}>
                          <h4>Organizational</h4>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Customer Experience
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Integrity
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Marketing
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Professionalism
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Team Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Administration
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Critical Thinking
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Presentation Skill
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Conflict Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Quality Of Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Attendance
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Efficiency
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Ability To Meet Deadline
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Status
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>Active</option>
                              <option>Inactive</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggleEdit}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={toggleEdit}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* DELETE MODAL */}

                  <Col sm={6} md={4} xl={3}>
                    <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
                      <ModalHeader className="mt-0" toggle={toggleDelete}>
                        Are you sure want to delete Performance Indicator?
                      </ModalHeader>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger">
                          Delete
                        </button>
                        <button type="button" className="btn btn-secondary">
                          Close
                        </button>
                      </div>
                    </Modal>
                  </Col>

                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Deparment</th>
                          <th scope="col">Added By</th>
                          <th scope="col">Created Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Agent I</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "100px" }}
                            >
                              Above Average
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Team Leader</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "100px" }}
                            >
                              Poor
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Android Developer</td>
                          <td>Project Management</td>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alil Jay
                            </div>
                          </td>
                          <td>8/25/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "100px" }}
                            >
                              Above Average
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>Team Leader</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Brent Alil
                            </div>
                          </td>
                          <td>6/6/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "100px" }}
                            >
                              Above Average
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>Agent I</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Blossom Brent
                            </div>
                          </td>
                          <td>1/15/2024</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "100px" }}
                            >
                              Poor
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>Agent I</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "100px" }}
                            >
                              Above Average
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>Team Leader</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "100px" }}
                            >
                              Poor
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>Android Developer</td>
                          <td>Project Management</td>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Alil Jay
                            </div>
                          </td>
                          <td>8/25/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "100px" }}
                            >
                              Above Average
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>Team Leader</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Brent Alil
                            </div>
                          </td>
                          <td>6/6/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "100px" }}
                            >
                              Above Average
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">10</th>
                          <td>Agent I</td>
                          <td>CSR</td>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Blossom Brent
                            </div>
                          </td>
                          <td>1/15/2024</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "100px" }}
                            >
                              Poor
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PerformanceIndicator
