import React from "react"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Pages Calendar
import Calendar from "../pages/Calendar/index"

//Leaves
import Leaves from "../pages/DTR/Leaves"

//Attendance
import Attendance from "../pages/DTR/Attendance"

//Attendance
import Scheduler from "../pages/DTR/Scheduler"

//DRT summary
import DtrSummary from "../pages/DTR/DtrSummary"

// Jobs
import JobDashboard from "../pages/Jobs/DashBoard"
import ApplicantForm from "../pages/Jobs/ApplicantForm"
import ManageJob from "../pages/Jobs/OpenPosition"
import Shortlisted from "../pages/Jobs/Shortlisted"
import ListApplicant from "../pages/Jobs/ListApplicant"

// Announcement
import Holiday from "../pages/Announcement/holiday"
import Event from "../pages/Announcement/event"
import Birthday from "../pages/Announcement/birthday"

// Form
import COE from "../pages/Form/coe"
import PrintCoe from "../pages/Form/PrintCoe"
import LoginLogout from "../pages/Form/LoginLogout"
import OBF from "../pages/Form/OBF"

//Attendance
import AddAttendance from "../pages/DTR/AddAttendance"
import AttendanceAdmin from "../pages/DTR/AttendanceAdmin"

//Email
import EmailInbox from "../pages/Email/email-inbox"
import EmailRead from "../pages/Email/email-read"
import EmailCompose from "../pages/Email/email-compose"

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert"
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic"
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
// Charts
import ChartApex from "../pages/Charts/Apexcharts"
import ChartistChart from "../pages/Charts/ChartistChart"
import ChartjsChart from "../pages/Charts/ChartjsChart"
import EChart from "../pages/Charts/EChart"
import SparklineChart from "../pages/Charts/SparklineChart"

// Employee
import Employee201 from "../pages/Employee/Employee"
import EmpLeaves from "../pages/Employee/Leaves"
import Appraisal from "../pages/Employee/Appraisal"
import Disciplinary from "../pages/Employee/Disciplinary"

// Performance
import PerformanceIndicator from "../pages/Performance/indicator"
import PerformanceReview from "../pages/Performance/review"
import PerformanceAppraisal from "../pages/Performance/appraisal"

// Goal
import GoalList from "../pages/Goal/goal-list"
import GoalType from "../pages/Goal/goal-type"

// Department
import DepartmentList from "../pages/Department/Department"

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle"
import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Icons
import IconDripicons from "../pages/Icons/IconDripicons"
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
import TypiconsIcon from "../pages/Icons/IconTypicons"
import IconIon from "../pages/Icons/IconIon"
import ThemifyIcon from "../pages/Icons/IconThemify"
import IconFontawesome from "../pages/Icons/IconFontawesome"

//Tables
import BasicTables from "../pages/Tables/BasicTables"
import DatatableTables from "../pages/Tables/DatatableTables"
import ResponsiveTables from "../pages/Tables/ResponsiveTables"
import EditableTables from "../pages/Tables/EditableTables"

// Forms
import FormElements from "../pages/Forms/FormElements"
import FormAdvanced from "../pages/Forms/FormAdvanced"
import FormEditors from "../pages/Forms/FormEditors"
import FormValidations from "../pages/Forms/FormValidations"
import FormMask from "../pages/Forms/FormMask"
import FormRepeater from "../pages/Forms/FormRepeater"
import FormUpload from "../pages/Forms/FormUpload"
import FormWizard from "../pages/Forms/FormWizard"
import FormXeditable from "../pages/Forms/FormXeditable"

//Ui
import UiAlert from "../pages/Ui/UiAlert"
import UiButtons from "../pages/Ui/UiButtons"
import UiCards from "../pages/Ui/UiCards"
import UiCarousel from "../pages/Ui/UiCarousel"
import UiColors from "../pages/Ui/UiColors"
import UiDropdown from "../pages/Ui/UiDropdown"
import UiGeneral from "../pages/Ui/UiGeneral"
import UiGrid from "../pages/Ui/UiGrid"
import UiImages from "../pages/Ui/UiImages"
import UiLightbox from "../pages/Ui/UiLightbox"
import UiModal from "../pages/Ui/UiModal"
import UiProgressbar from "../pages/Ui/UiProgressbar"
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
import UiTypography from "../pages/Ui/UiTypography"
import UiVideo from "../pages/Ui/UiVideo"
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
import UiRating from "../pages/Ui/UiRating"
import UiRangeSlider from "../pages/Ui/UiRangeSlider"
import UiUtilities from "pages/Ui/UiUtilities"
import UiOffcanvas from "pages/Ui/UiOffcanvas"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesInvoice from "../pages/Utility/PagesInvoice"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
import PagesGallery from "../pages/Utility/PagesGallery"
import PagesDirectory from "../pages/Utility/PagesDirectory"
import PagesProfile from "pages/Utility/pages-profile"
import Payroll from "pages/Payroll"
import DesignationList from "pages/Designation/Designation"
import LeavesList from "pages/Settings/Leaves"
import HolidaysList from "pages/Settings/Holidays"
import EmployeeList from "pages/Reports/Employees"
import AssetsList from "pages/Assets/AssetList"
import BenefitsList from "pages/Settings/Benefits"
import EmployeePayslip from "pages/Payroll/payslip"
import AssetCategoryList from "pages/Assets/AssetCategory"
import AssetAssignmentList from "pages/Assets/AssetAssignment"
import Adjustments from "pages/Payroll/adjustments"

const userRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //DTR
  { path: "/Leaves", component: <Leaves /> },
  { path: "/dtr-summary", component: <DtrSummary /> },
  { path: "/Attendance", component: <Attendance /> },
  { path: "/AddAttendance", component: <AddAttendance /> },
  { path: "/AttendanceAdmin", component: <AttendanceAdmin /> },
  { path: "/Scheduler", component: <Scheduler /> },

  // Jobs
  { path: "/job-dashboard", component: <JobDashboard /> },
  { path: "/job-manage", component: <ManageJob /> },
  { path: "/job-shortlisted", component: <Shortlisted /> },
  { path: "/job-list-applicant", component: <ListApplicant /> },

  { path: "/payroll", component: <Payroll /> },
  { path: "/payslip", component: <EmployeePayslip /> },
  { path: "/adjustments", component: <Adjustments /> },

  // //calendar
  { path: "/calendar", component: <Calendar /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },
  { path: "/email-compose", component: <EmailCompose /> },

  // Email Template
  { path: "/email-template-alert", component: <Emailtemplatealert /> },
  { path: "/email-template-basic", component: <Emailtemplatebasic /> },
  { path: "/email-template-billing", component: <Emailtemplatebilling /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartist-charts", component: <ChartistChart /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },

  //Employee
  { path: "/employee-201", component: <Employee201 /> },
  { path: "/empleaves", component: <EmpLeaves /> },
  { path: "/appraisal", component: <Appraisal /> },
  { path: "/disciplinary", component: <Disciplinary /> },

  //Performance
  { path: "/performanceindicator", component: <PerformanceIndicator /> },
  { path: "/performancereview", component: <PerformanceReview /> },
  { path: "/performanceappraisal", component: <PerformanceAppraisal /> },

  //Goal
  { path: "/goal-list", component: <GoalList /> },
  { path: "/goal-type", component: <GoalType /> },

  //Announcement
  { path: "/holiday", component: <Holiday /> },
  { path: "/event", component: <Event /> },
  { path: "/birthday", component: <Birthday /> },

  //Form
  { path: "/coe", component: <COE /> },
  { path: "/print-coe", component: <PrintCoe /> },
  { path: "/loginlogout", component: <LoginLogout /> },
  { path: "/obf", component: <OBF /> },

  //Department
  { path: "/department-list", component: <DepartmentList /> },

  //Reports
  { path: "/employee-reports", component: <EmployeeList /> },

  //Designation
  { path: "/designation-list", component: <DesignationList /> },

  //Assets
  { path: "/asset-list", component: <AssetsList /> },
  { path: "/asset-assignment", component: <AssetAssignmentList /> },
  { path: "/asset-category", component: <AssetCategoryList /> },

  //Settings
  { path: "/leaves-list", component: <LeavesList /> },
  { path: "/holidays-list", component: <HolidaysList /> },
  { path: "/benefits-list", component: <BenefitsList /> },

  // Icons
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },
  { path: "/icons-ion", component: <IconIon /> },
  { path: "/icons-themify", component: <ThemifyIcon /> },
  { path: "/icons-typicons", component: <TypiconsIcon /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },

  // Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-advanced", component: <FormAdvanced /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-validation", component: <FormValidations /> },
  { path: "/form-xeditable", component: <FormXeditable /> },

  // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-utilities", component: <UiUtilities /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PagesTimeline /> },
  { path: "/pages-invoice", component: <PagesInvoice /> },
  { path: "/pages-directory", component: <PagesDirectory /> },
  { path: "/pages-faqs", component: <PagesFaqs /> },
  { path: "/pages-pricing", component: <PagesPricing /> },
  { path: "/pages-gallery", component: <PagesGallery /> },
  { path: "/pages-profile", component: <PagesProfile /> },

  // this route should be at the end of all other routes
  { path: "/", component: <Dashboard /> },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Jobs
  { path: "/job-applicantForm", component: <ApplicantForm /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-login-2", component: <Login2 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/pages-register-2", component: <Register2 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
  { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  { path: "/page-confirm-mail", component: <ConfirmMail /> },
  { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  { path: "/auth-email-verification", component: <EmailVerification /> },
  { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  {
    path: "/auth-two-step-verification-2",
    component: <TwostepVerification2 />,
  },
]

export { userRoutes, authRoutes }
