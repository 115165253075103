import React, { useState, Component } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"

import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import { Link } from "react-router-dom"

const PerformanceAppraisal = () => {
  const [modal_center, setmodal_center] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const [drp_secondary, setDrp_secondary] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  document.title = "Form | COE"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="COE"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Request COE</h2>
                  </Row>
               
                  {/* ADD MODAL */}
                  <Modal
                    isOpen={modal_center}
                    toggle={tog_center}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={tog_center}>
                      Create COE
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Employee
                        </label>
                        <div className="col-md-10 mb-2">
                          <select className="form-control">
                            <option>-- Select Employee --</option>
                            <option>John Doe</option>
                            <option>Aljon Alil</option>
                            <option>Jay Aljon</option>
                            <option>Brent Blossom</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Select Date
                        </label>
                        <div className="col-md-10 mb-2">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <hr />
                      <Row className="mb-3 text-center">
                        <Col lg={6}>
                          <h4>Technical</h4>
                        </Col>
                        <Col lg={6}>
                          <h4>Organizational</h4>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Customer Experience
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Integrity
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Marketing
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Professionalism
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Team Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Administration
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Critical Thinking
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Presentation Skill
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Conflict Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Quality Of Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Attendance
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Efficiency
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Ability To Meet Deadline
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Status
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>Active</option>
                              <option>active</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={tog_center}>
                        Add
                      </Button>
                      <Button color="secondary" onClick={tog_center}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* EDIT MODAL */}
                  <Modal
                    isOpen={modalEdit}
                    toggle={toggleEdit}
                    centered
                    size="lg"
                  >
                    <ModalHeader className="mt-0" toggle={toggleEdit}>
                      Edit COE
                    </ModalHeader>
                    <ModalBody className="m-2">
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Employee
                        </label>
                        <div className="col-md-10 mb-2">
                          <select className="form-control">
                            <option>-- Select Employee --</option>
                            <option>John Doe</option>
                            <option>Aljon Alil</option>
                            <option>Jay Aljon</option>
                            <option>Brent Blossom</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label className="col-md-2 col-form-label">
                          Select Date
                        </label>
                        <div className="col-md-10 mb-2">
                          <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                          />
                        </div>
                      </Row>
                      <hr />
                      <Row className="mb-3 text-center">
                        <Col lg={6}>
                          <h4>Technical</h4>
                        </Col>
                        <Col lg={6}>
                          <h4>Organizational</h4>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Customer Experience
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Integrity
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Marketing
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Professionalism
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Team Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Administration
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Critical Thinking
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Presentation Skill
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Conflict Management
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Quality Of Work
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Attendance
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Efficiency
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Ability To Meet Deadline
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>None</option>
                              <option>Beginner</option>
                              <option>Intermediate</option>
                              <option>Advanced</option>
                              <option>Expert / Leader</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={12}>
                          <label
                            htmlFor="example-date-input"
                            className="form-label"
                          >
                            Status
                          </label>
                          <div className="mb-2">
                            <select className="form-control">
                              <option>Active</option>
                              <option>active</option>
                            </select>
                          </div>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="primary" onClick={toggleEdit}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={toggleEdit}>
                        Close
                      </Button>{" "}
                    </ModalFooter>
                  </Modal>

                  {/* DELETE MODAL */}

                  <Col sm={6} md={4} xl={3}>
                    <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
                      <ModalHeader className="mt-0" toggle={toggleDelete}>
                        Are you sure want to delete COE?
                      </ModalHeader>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-danger">
                          Delete
                        </button>
                        <button type="button" className="btn btn-secondary">
                          Close
                        </button>
                      </div>
                    </Modal>
                  </Col>

                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Employee</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Deparment</th>
                          <th scope="col">Requested Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>Manager</td>
                          <td>CSR</td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>
                            <Link to="/print-coe" className="btn btn-primary btn-sm">
                              Print
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>Agent</td>
                          <td>CSR</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>
                            <Link to="/print-coe" className="btn btn-primary btn-sm">
                              Print
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Francis A
                            </div>
                          </td>
                          <td>Agent</td>
                          <td>CSR</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              active
                            </span>
                          </td>
                          <td>
                            <Link to="/print-coe" className="btn btn-primary btn-sm">
                              Print
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Robert Doe
                            </div>
                          </td>
                          <td>Agent</td>
                          <td>CSR</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              active
                            </span>
                          </td>
                          <td>
                            <Link to="/print-coe" className="btn btn-primary btn-sm">
                              Print
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jhon Doe
                            </div>
                          </td>
                          <td>Agent</td>
                          <td>CSR</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              active
                            </span>
                          </td>
                          <td>
                            <Link to="/print-coe" className="btn btn-primary btn-sm">
                              Print
                            </Link>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Marc Jhon
                            </div>
                          </td>
                          <td>Agent</td>
                          <td>CSR</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              active
                            </span>
                          </td>
                          <td>
                            <Link to="/print-coe" className="btn btn-primary btn-sm">
                              Print
                            </Link>
                          </td>
                        </tr>
                 
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PerformanceAppraisal
