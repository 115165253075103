import React, { useState, Component } from "react"
import { Link } from "react-router-dom"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  Container,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  CardSubtitle,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  Nav,
} from "reactstrap"

import * as Yup from "yup"
import { useFormik } from "formik"

import { DateFilter, Gender } from "constants/data"
import styled from "styled-components"

import classnames from "classnames"

import SimpleBar from "simplebar-react"

// Import Images
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import ProfileChart from "../Utility/profile-chart"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"

const Employee201 = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [singlebtn, setSinglebtn] = useState(false)
  const [genderbtn, setGenderbtn] = useState(false)
  const [filter, setFilter] = useState("Year")
  const [gender, setGender] = useState("Male")
  const [drp_secondary, setDrp_secondary] = useState(false)

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1")
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstname: "Mark",
      lastname: "Otto",
      city: "City",
      state: "State",
      zip: "Zip",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required("Please Enter Your First Name"),
      lastname: Yup.string().required("Please Enter Your Last Name"),
      city: Yup.string().required("Please Enter Your City"),
      state: Yup.string().required("Please Enter Your State"),
      zip: Yup.string().required("Please Enter Your Zip"),
    }),
    onSubmit: values => {
      console.log("values", values)
    },
  })

  const [activeTab, setactiveTab] = useState(1)
  const [activeTabProgress, setactiveTabProgress] = useState(1)
  const [progressValue, setprogressValue] = useState(25)
  const [activeTabVartical, setoggleTabVertical] = useState(1)

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab)
      }
    }
  }

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
      }
    }
  }

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTabProgress(tab)

        if (tab === 1) {
          setprogressValue(25)
        }
        if (tab === 2) {
          setprogressValue(50)
        }
        if (tab === 3) {
          setprogressValue(75)
        }
        if (tab === 4) {
          setprogressValue(100)
        }
      }
    }
  }

  document.title = "Employee | Employee 201"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="ByteFlare"
            title="Employee"
            breadcrumbItem="Employee List"
          />

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Employee List</h2>
                  </Row>
                  <hr />
                  <Row>
                    <Col lg={3}>
                      <FilterTxt
                        name="search"
                        type="text"
                        className="form-control w-100 h-100"
                        placeholder="Search..."
                      />
                    </Col>
                    <Col lg={5}>
                      <div className="btn-group mt-1">
                        <ButtonDropdown
                          isOpen={drp_secondary}
                          toggle={() => setDrp_secondary(!drp_secondary)}
                        >
                          <DropdownToggle
                            tag="button"
                            className="btn btn-outline-info"
                          >
                            <i
                              className="typcn typcn-group"
                              style={{ fontSize: "17px" }}
                            />{" "}
                            Select Department{" "}
                            <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>CSR</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Frontend</DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem>Project Management</DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                    <Col lg={4}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAdd}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="typcn typcn-user-add"
                            style={{ fontSize: "17px" }}
                          ></i>{" "}
                          Add Employee
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">Employee ID</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Contact</th>
                          <th scope="col">Department</th>
                          <th scope="col">Status</th>
                          <th scope="col" colSpan="2">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">EMP - 001</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              John Doe
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td>(+63) 997 258 9638</td>
                          <td>CSR</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 002</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Lorem Ipsum
                            </div>
                          </td>

                          <td>loremipsum@gmail.com</td>
                          <td>(+63) 998 185 1589</td>
                          <td>Frontend</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Resigned
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 003</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Doe Lorem
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td> (+63) 995 388 9031</td>
                          <td>Project Manager</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "60px" }}
                            >
                              Archived
                            </span>
                          </td>
                          <th>
                            {" "}
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 004</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              John Doe
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td>(+63) 997 258 9638</td>
                          <td>CSR</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 005</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Lorem Ipsum
                            </div>
                          </td>

                          <td>loremipsum@gmail.com</td>
                          <td>(+63) 998 185 1589</td>
                          <td>Frontend</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Resigned
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 006</th>
                          <td>
                            <div>
                              <img
                                src={user5}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              John Doe
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td>(+63) 997 258 9638</td>
                          <td>CSR</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 007</th>
                          <td>
                            <div>
                              <img
                                src={user4}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Lorem Ipsum
                            </div>
                          </td>

                          <td>loremipsum@gmail.com</td>
                          <td>(+63) 998 185 1589</td>
                          <td>Frontend</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Resigned
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 008</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Doe Lorem
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td> (+63) 995 388 9031</td>
                          <td>Project Manager</td>
                          <td>
                            <span
                              className="badge bg-warning"
                              style={{ width: "60px" }}
                            >
                              Archived
                            </span>
                          </td>
                          <th>
                            {" "}
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 009</th>
                          <td>
                            <div>
                              <img
                                src={user6}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              John Doe
                            </div>
                          </td>

                          <td>doelorem@gmail.com</td>
                          <td>(+63) 997 258 9638</td>
                          <td>CSR</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <th scope="row">EMP - 010</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Lorem Ipsum
                            </div>
                          </td>

                          <td>loremipsum@gmail.com</td>
                          <td>(+63) 998 185 1589</td>
                          <td>Frontend</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Resigned
                            </span>
                          </td>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "21px" }}
                            ></i>
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "21px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>

          {/* ADD MODAL*/}
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} centered size="xl">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add"
                    style={{ fontSize: "23px" }}
                  ></i>{" "}
                  Add New Employee
                </ModalHeader>{" "}
                {/* ADD MODAL*/}
                <ModalBody>
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                            <div className="steps clearfix">
                              <ul>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 1,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      current: activeTab === 1,
                                    })}
                                    onClick={() => {
                                      setactiveTab(1)
                                    }}
                                  >
                                    <span className="number">1.</span> Employee
                                    Details
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 2,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 2,
                                    })}
                                    onClick={() => {
                                      setactiveTab(2)
                                    }}
                                  >
                                    <span className="number">2.</span> Benefits
                                    Info
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 3,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 3,
                                    })}
                                    onClick={() => {
                                      setactiveTab(3)
                                    }}
                                  >
                                    <span className="number">3.</span>
                                    Salary
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 4,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 4,
                                    })}
                                    onClick={() => {
                                      setactiveTab(4)
                                    }}
                                  >
                                    <span className="number">4.</span>
                                    Confirm Detail
                                  </NavLink>
                                </NavItem>
                              </ul>
                            </div>
                            <div className="content clearfix">
                              <TabContent
                                activeTab={activeTab}
                                className="body"
                              >
                                <TabPane tabId={1}>
                                  <Form>
                                    {/* <Row>
                                      <Col md={6}>
                                        <Row>
                                          <label
                                            htmlFor="txtFirstNameBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Employee ID
                                          </label>
                                          <div className="col-lg-5">
                                            <Input
                                              id="txtFirstNameBilling"
                                              name="txtFirstNameBilling"
                                              type="text"
                                              readonly
                                              className="form-control"
                                              placeholder="EMP - 001"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <hr /> */}
                                    <Row>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFirstNameBilling"
                                            className="col-lg-4 col-form-label"
                                          >
                                            First name
                                          </label>
                                          <div className="col-lg-8">
                                            <Input
                                              id="txtFirstNameBilling"
                                              name="txtFirstNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your first name"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameBilling"
                                            className="col-lg-4 col-form-label"
                                          >
                                            Middle name
                                          </label>
                                          <div className="col-lg-8">
                                            <Input
                                              id="txtLastNameBilling"
                                              name="txtLastNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your middle name"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Last name
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtLastNameBilling"
                                              name="txtLastNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your last name"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="ddlCreditCardType"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Gender
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              type="select"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                              className="form-select"
                                            >
                                              <option value="">
                                                -- Please Select --
                                              </option>
                                              <option value="AE">Male</option>
                                              <option value="VI">Female</option>
                                              <option value="MC">Other</option>
                                            </Input>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtExpirationDate"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Birthdate
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              className="form-control"
                                              type="date"
                                              defaultValue="2019-08-19"
                                              id="example-datetime-local-input"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameBilling"
                                            className="col-lg-4 col-form-label"
                                          >
                                            Birthplace
                                          </label>
                                          <div className="col-lg-8">
                                            <Input
                                              id="txtLastNameBilling"
                                              name="txtLastNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your Birthplace"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtAddress1Billing"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Address
                                          </label>
                                          <div className="col-lg-9">
                                            <textarea
                                              id="txtAddress1Billing"
                                              name="txtAddress1Billing"
                                              rows="4"
                                              className="form-control"
                                              placeholder="Enter your complete address"
                                            ></textarea>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCompanyBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Mobile No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtCompanyBilling"
                                              name="txtCompanyBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your mobile number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCityBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Civil status
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              type="select"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                              className="form-select"
                                            >
                                              <option value="">
                                                -- Please Select --
                                              </option>
                                              <option value="AE">Single</option>
                                              <option value="VI">
                                                Married
                                              </option>
                                              <option value="MC">
                                                Widowed
                                              </option>
                                            </Input>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtStateProvinceBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Email
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtStateProvinceBilling"
                                              name="txtStateProvinceBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Email"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtTelephoneBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Contact person
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtTelephoneBilling"
                                              name="txtTelephoneBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter contact person"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFaxBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Contact No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtFaxBilling"
                                              name="txtFaxBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter contact number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtTelephoneBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Designation
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtTelephoneBilling"
                                              name="txtTelephoneBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Designation"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFaxBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Rank
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtFaxBilling"
                                              name="txtFaxBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Rank"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>

                                <TabPane tabId={2}>
                                  <Form>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFirstNameShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            SSS Number
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtFirstNameShipping"
                                              name="txtFirstNameShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter SSS Number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            TIN Number
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtLastNameShipping"
                                              name="txtLastNameShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter TIN number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCompanyShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Philhealth No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtCompanyShipping"
                                              name="txtCompanyShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Philhealth No."
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtEmailAddressShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Pagibig No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtEmailAddressShipping"
                                              name="txtEmailAddressShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Pagibig No."
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>

                                <TabPane tabId={3}>
                                  <Form>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtNameCard"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Gross Salary
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtNameCard"
                                              name="txtNameCard"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Gross Salary"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCreditCardNumber"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Net Salary
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtCreditCardNumber"
                                              name="txtCreditCardNumber"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Net Salary"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>
                                <TabPane tabId={4}>
                                  <div className="row justify-content-center">
                                    <Col lg="6">
                                      <div className="text-center">
                                        <div className="mb-4">
                                          <i className="mdi mdi-check-circle-outline text-success display-4" />
                                        </div>
                                        <div>
                                          <h5>Confirm Detail</h5>
                                          {/* <p className="text-muted">
                                            If several languages coalesce, the
                                            grammar of the resulting
                                          </p> */}
                                        </div>
                                      </div>
                                    </Col>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </div>
                            <div className="actions clearfix">
                              <ul>
                                <li
                                  className={
                                    activeTab === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  {activeTab > 1 &&
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab - 1)
                                    }}
                                  >
                                    Previous
                                  </Link>
                                  }
                                </li>
                                <li
                                  className={activeTab === 4 ? "next" : "next"}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                      activeTab === 4 && toggleAdd()
                                    }}
                                  >
                                     {activeTab === 4 ? "Confirm":"Next"}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} centered size="xl">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  <i
                    className="typcn typcn-user-add"
                    style={{ fontSize: "23px" }}
                  ></i>{" "}
                  Edit Employee
                </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col sm="12">
                      <Card>
                        <CardBody>
                          <div className="form-horizontal form-wizard-wrapper wizard clearfix">
                            <div className="steps clearfix">
                              <ul>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 1,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      current: activeTab === 1,
                                    })}
                                    onClick={() => {
                                      setactiveTab(1)
                                    }}
                                  >
                                    <span className="number">1.</span> Employee
                                    Details
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 2,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 2,
                                    })}
                                    onClick={() => {
                                      setactiveTab(2)
                                    }}
                                  >
                                    <span className="number">2.</span> Benefits
                                    Info
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 3,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 3,
                                    })}
                                    onClick={() => {
                                      setactiveTab(3)
                                    }}
                                  >
                                    <span className="number">3.</span>
                                    Salary
                                  </NavLink>
                                </NavItem>
                                <NavItem
                                  className={classnames({
                                    current: activeTab === 4,
                                  })}
                                >
                                  <NavLink
                                    className={classnames({
                                      active: activeTab === 4,
                                    })}
                                    onClick={() => {
                                      setactiveTab(4)
                                    }}
                                  >
                                    <span className="number">4.</span>
                                    Confirm Detail
                                  </NavLink>
                                </NavItem>
                              </ul>
                            </div>
                            <div className="content clearfix">
                              <TabContent
                                activeTab={activeTab}
                                className="body"
                              >
                                <TabPane tabId={1}>
                                  <Form>
                                    {/* <Row>
                                      <Col md={6}>
                                        <Row>
                                          <label
                                            htmlFor="txtFirstNameBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Employee ID
                                          </label>
                                          <div className="col-lg-5">
                                            <Input
                                              id="txtFirstNameBilling"
                                              name="txtFirstNameBilling"
                                              type="text"
                                              readonly
                                              className="form-control"
                                              placeholder="EMP - 001"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <hr /> */}
                                    <Row>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFirstNameBilling"
                                            className="col-lg-4 col-form-label"
                                          >
                                            First name
                                          </label>
                                          <div className="col-lg-8">
                                            <Input
                                              id="txtFirstNameBilling"
                                              name="txtFirstNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your first name"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameBilling"
                                            className="col-lg-4 col-form-label"
                                          >
                                            Middle name
                                          </label>
                                          <div className="col-lg-8">
                                            <Input
                                              id="txtLastNameBilling"
                                              name="txtLastNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your middle name"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Last name
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtLastNameBilling"
                                              name="txtLastNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your last name"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="ddlCreditCardType"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Gender
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              type="select"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                              className="form-select"
                                            >
                                              <option value="">
                                                -- Please Select --
                                              </option>
                                              <option value="AE">Male</option>
                                              <option value="VI">Female</option>
                                              <option value="MC">Other</option>
                                            </Input>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtExpirationDate"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Birthdate
                                          </label>
                                          <div className="col-md-9">
                                            <input
                                              className="form-control"
                                              type="date"
                                              defaultValue="2019-08-19"
                                              id="example-datetime-local-input"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={4}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameBilling"
                                            className="col-lg-4 col-form-label"
                                          >
                                            Birthplace
                                          </label>
                                          <div className="col-lg-8">
                                            <Input
                                              id="txtLastNameBilling"
                                              name="txtLastNameBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your Birthplace"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtAddress1Billing"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Address
                                          </label>
                                          <div className="col-lg-9">
                                            <textarea
                                              id="txtAddress1Billing"
                                              name="txtAddress1Billing"
                                              rows="4"
                                              className="form-control"
                                              placeholder="Enter your complete address"
                                            ></textarea>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCompanyBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Mobile No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtCompanyBilling"
                                              name="txtCompanyBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter your mobile number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCityBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Civil status
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              type="select"
                                              id="ddlCreditCardType"
                                              name="ddlCreditCardType"
                                              className="form-select"
                                            >
                                              <option value="">
                                                -- Please Select --
                                              </option>
                                              <option value="AE">Single</option>
                                              <option value="VI">
                                                Married
                                              </option>
                                              <option value="MC">
                                                Widowed
                                              </option>
                                            </Input>
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtStateProvinceBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Email
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtStateProvinceBilling"
                                              name="txtStateProvinceBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Email"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtTelephoneBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Contact person
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtTelephoneBilling"
                                              name="txtTelephoneBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter contact person"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFaxBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Contact No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtFaxBilling"
                                              name="txtFaxBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter contact number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtTelephoneBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Designation
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtTelephoneBilling"
                                              name="txtTelephoneBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Designation"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFaxBilling"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Rank
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtFaxBilling"
                                              name="txtFaxBilling"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Rank"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>

                                <TabPane tabId={2}>
                                  <Form>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtFirstNameShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            SSS Number
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtFirstNameShipping"
                                              name="txtFirstNameShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter SSS Number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtLastNameShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            TIN Number
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtLastNameShipping"
                                              name="txtLastNameShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter TIN number"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCompanyShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Philhealth No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtCompanyShipping"
                                              name="txtCompanyShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Philhealth No."
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtEmailAddressShipping"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Pagibig No.
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtEmailAddressShipping"
                                              name="txtEmailAddressShipping"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Pagibig No."
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>

                                <TabPane tabId={3}>
                                  <Form>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtNameCard"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Salary
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtNameCard"
                                              name="txtNameCard"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Salary"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={6}>
                                        <Row className="mb-3">
                                          <label
                                            htmlFor="txtCreditCardNumber"
                                            className="col-lg-3 col-form-label"
                                          >
                                            Net Salary
                                          </label>
                                          <div className="col-lg-9">
                                            <Input
                                              id="txtCreditCardNumber"
                                              name="txtCreditCardNumber"
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Net Salary"
                                            />
                                          </div>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </Form>
                                </TabPane>
                                <TabPane tabId={4}>
                                  <div className="row justify-content-center">
                                    <Col lg="6">
                                      <div className="text-center">
                                        <div className="mb-4">
                                          <i className="mdi mdi-check-circle-outline text-success display-4" />
                                        </div>
                                        <div>
                                          <h5>Confirm Detail</h5>
                                          {/* <p className="text-muted">
                                            If several languages coalesce, the
                                            grammar of the resulting
                                          </p> */}
                                        </div>
                                      </div>
                                    </Col>
                                  </div>
                                </TabPane>
                              </TabContent>
                            </div>
                            <div className="actions clearfix">
                              <ul>
                                <li
                                  className={
                                    activeTab === 1
                                      ? "previous disabled"
                                      : "previous"
                                  }
                                >
                                  {activeTab > 1 &&
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab - 1)
                                    }}
                                  >
                                    Previous
                                  </Link>
                                  }
                                </li>
                                <li
                                  className={activeTab === 4 ? "next" : "next"}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      toggleTab(activeTab + 1)
                                    }}
                                  >
                                     {activeTab === 4 ? "Confirm":"Next"}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* VIEW MODAL*/}
          <Col sm={12} md={12} xl={12} lg={12}>
            <div className="my-4 text-center">
              <Modal
                isOpen={modalView}
                toggle={toggleView}
                centered
                size="xl"
                className="modal-fullscreen"
              >
                <ModalBody>
                  <Row>
                    <Col xl={3}>
                      <div className="user-sidebar">
                        <Card>
                          <CardBody>
                            <div className="">
                              <div className="d-flex justify-content-end">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    tag="a"
                                    className="btn dropdown-toggle text-muted fs-5"
                                    id="dropdownMenuButton"
                                    role="button"
                                  >
                                    <i className="mdi mdi-dots-vertical"></i>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>Action</DropdownItem>
                                    <DropdownItem>Another action</DropdownItem>
                                    <DropdownItem>
                                      Something else here
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                            </div>
                            <div className="mt-n4 position-relative">
                              <div className="text-center">
                                <img
                                  src={user5}
                                  alt=""
                                  className="avatar-xl rounded-circle img-thumbnail"
                                />

                                <div className="mt-3">
                                  <h5 className="">John Doe</h5>
                                  <div>
                                    <Link to="#" className="text-muted m-1">
                                      CSR
                                    </Link>
                                  </div>

                                  <div className="mt-4">
                                    <Link
                                      to="#"
                                      className="btn btn-primary waves-effect waves-light btn-sm me-1"
                                    >
                                      Send Message
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-primary waves-effect waves-light btn-sm"
                                    >
                                      Email
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>

                        <Card>
                          <div className="card-header">
                            <h4 className="card-title">Evaluation Stat</h4>
                          </div>
                          <CardBody>
                            <p className="font-size-15 mb-1">
                              Lorem ipsum Lorem ipsum.
                            </p>
                            <p className="font-size-15">
                              Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                              Lorem ipsum Lorem ipsum
                            </p>
                            <p className="text-muted">
                              Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                              Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                              Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                            </p>
                            <h5 className="font-size-15"> Lorem ipsum :</h5>
                            <ul className="list-unstyled mb-0 pt-1">
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                Donec vitae libero venenatis faucibus
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                Quisque rutrum aenean imperdiet
                              </li>
                              <li className="py-1">
                                <i className="mdi mdi-circle-medium me-1 text-success align-middle"></i>
                                Integer ante a consectetuer eget
                              </li>
                            </ul>
                          </CardBody>
                        </Card>

                        <Card>
                          <CardBody>
                            <h5 className="card-title mb-4">Skills</h5>
                            <div className="d-flex flex-wrap gap-2 fs-15">
                              <Link to="#" className="badge badge-soft-primary">
                                Photoshop
                              </Link>
                              <Link to="#" className="badge badge-soft-primary">
                                illustrator
                              </Link>
                              <Link to="#" className="badge badge-soft-primary">
                                HTML
                              </Link>
                              <Link to="#" className="badge badge-soft-primary">
                                CSS
                              </Link>
                              <Link to="#" className="badge badge-soft-primary">
                                Javascript
                              </Link>
                              <Link to="#" className="badge badge-soft-primary">
                                Php
                              </Link>
                              <Link to="#" className="badge badge-soft-primary">
                                Python
                              </Link>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    </Col>

                    <Col xl={9}>
                      <Card>
                        <CardBody>
                          <div id="profile-chart" className="apex-charts">
                            <ProfileChart />
                          </div>
                        </CardBody>
                      </Card>

                      <Card>
                        <Nav
                          tabs
                          className="nav nav-tabs nav-tabs-custom nav-justified"
                        >
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1")
                              }}
                            >
                              <i className="bx bx-user-circle font-size-20"></i>
                              <span className="d-none d-sm-block">
                                Employee Details
                              </span>
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "2",
                              })}
                              onClick={() => {
                                toggleCustom("2")
                              }}
                            >
                              <i className="bx bx-clipboard font-size-20"></i>
                              <span className="d-none d-sm-block">
                                Personal Info
                              </span>
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent activeTab={customActiveTab} className="p-4">
                          <TabPane tabId="1">
                            <div>
                              <h5 className="font-size-16 mb-4">
                                <Card>
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      Employee Details
                                    </h4>
                                  </div>
                                  <CardBody>
                                    <ul className="list-unstyled mb-0">
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">Name</span>
                                        <span>John Doe</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Gender
                                        </span>
                                        <span>Male</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Birthdate
                                        </span>
                                        <span>8/19/2019</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Birthplace
                                        </span>
                                        <span>San Juan Metro Manila</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Address
                                        </span>
                                        <span>
                                          Blk 8 Lot 7 Argentina St. Ciudad
                                          Grande Dolores Taytay Rizal
                                        </span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Mobile No:
                                        </span>
                                        <span>0995 378 7881</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Civil Status
                                        </span>
                                        <span>Single</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Email
                                        </span>
                                        <span>johndoe@gmail.com</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Contact Person
                                        </span>
                                        <span>0977 787 8521</span>
                                      </li>
                                    </ul>
                                  </CardBody>
                                </Card>
                              </h5>
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div>
                              <h5 className="font-size-16 mb-4">
                                <Card>
                                  <div className="card-header">
                                    <h4 className="card-title">
                                      Personal Info
                                    </h4>
                                  </div>
                                  <CardBody>
                                    <ul className="list-unstyled mb-0">
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          SSS No:
                                        </span>
                                        <span>33-164656565-33</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Tin No:
                                        </span>
                                        <span>231-123-456-001</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Philhealth No:
                                        </span>
                                        <span>57-164655-2</span>
                                      </li>
                                      <li className="d-flex justify-content-between p-2 font-size-15">
                                        <span className="text-muted">
                                          Pagibig No:
                                        </span>
                                        <span>87-1646565-98</span>
                                      </li>
                                    </ul>
                                  </CardBody>
                                </Card>
                              </h5>
                            </div>
                          </TabPane>
                        </TabContent>
                      </Card>
                    </Col>
                  </Row>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggleView}>
                      Close
                    </Button>{" "}
                    {/* <Button color="primary">Save changes</Button> */}
                  </ModalFooter>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to Archive this Employee?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Archive
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Employee201
