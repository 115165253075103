import React, { useState } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"

import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import "chartist/dist/scss/chartist.scss"

import { DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const DepartmentList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [singlebtn, setSinglebtn] = useState(false)
  const [genderbtn, setGenderbtn] = useState(false)
  const [filter, setFilter] = useState("Year")
  const [gender, setGender] = useState("Male")

  document.title = "Department | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Department"
          />
          <Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Department
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      16 <i className="mdi mdi-arrow-up text-success ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-start mini-stat-img me-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Employees
                    </h5>
                    <h4 className="fw-medium font-size-24">
                      368{" "}
                      <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-end">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h1">Department 201</CardTitle> */}
                  <Row>
                    <p className="card-title-desc">List of Department</p>
                  </Row>
                  <Row>
                    <h2>Department</h2>
                    <Col lg={10}>
                      <div className="d-flex justify-content-start">
                        <Dropdown
                          isOpen={singlebtn}
                          toggle={() => setSinglebtn(!singlebtn)}
                        >
                          <DropdownToggle className="btn btn-secondary" caret>
                            {filter} <i className="mdi mdi-chevron-down" />
                          </DropdownToggle>
                          <DropdownMenu>
                            {Object.keys(DateFilter).map(data => (
                              <DropdownItem
                                key={data}
                                onClick={() => setFilter(DateFilter[data])}
                              >
                                {DateFilter[data]}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                        <FilterTxt
                          name="search"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <Button
                        onClick={toggleAdd}
                        size="sm"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Department
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Department</th>
                          <th>Department Head</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Frontend</td>
                          <td>John Doe</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            {/* <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "} */}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Backend</td>
                          <td>Will Smith</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            {/* <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "} */}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Project Management</td>
                          <td>Peter Parker</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            {/* <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "} */}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleDelete}
                              className="far fa-file-archive"
                              style={{ fontSize: "18px" }}
                            ></i>
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* VIEW MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalView} toggle={toggleView} size="lg">
                <ModalHeader className="mt-0" toggle={toggleView}>
                  View Department
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Department
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>--- Select Department ---</option>
                          <option>Frontend</option>
                          <option>Backend</option>
                          <option>Project Management</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Department Head
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>--- Select Department Head ---</option>
                          <option>John Doe</option>
                          <option>Will Smith</option>
                          <option>Peter Parker</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>--- Select Status ---</option>
                          <option>Active</option>
                          <option>Inactive</option>
                        </select>
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Department
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Department
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>--- Select Department ---</option>
                            <option>Frontend</option>
                            <option>Backend</option>
                            <option>Project Management</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Department Head
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>--- Select Department Head ---</option>
                            <option>John Doe</option>
                            <option>Will Smith</option>
                            <option>Peter Parker</option>
                          </select>
                        </div>
                      </Row>
                      <Row className="mb-3">
                        <label
                          htmlFor="example-text-input"
                          className="col-md-2 col-form-label"
                        >
                          Status
                        </label>
                        <div className="col-md-10">
                          <select className="form-control">
                            <option>--- Select Status ---</option>
                            <option>Active</option>
                            <option>Inactive</option>
                          </select>
                        </div>
                      </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Deparment
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Department
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>--- Select Department ---</option>
                          <option>Frontend</option>
                          <option>Backend</option>
                          <option>Project Management</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Department Head
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>--- Select Department Head ---</option>
                          <option>John Doe</option>
                          <option>Will Smith</option>
                          <option>Peter Parker</option>
                        </select>
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <select className="form-control">
                          <option>--- Select Status ---</option>
                          <option>Active</option>
                          <option>Inactive</option>
                        </select>
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to Archive?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default DepartmentList
