import React, { useState } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"


import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import "chartist/dist/scss/chartist.scss"

import { DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const AssetAssignmentList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [singlebtn, setSinglebtn] = useState(false)
  const [genderbtn, setGenderbtn] = useState(false)


  document.title = "Asset Assigment | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Asset"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h1">Asset 201</CardTitle> */}
                  <Row>
                    <p className="card-title-desc">
                      List of Asset Assignment
                    </p>
                  </Row>
                  <hr />
                  <Row>
                    <h2>Asset Assignment</h2>
                    <Col/>
                    <Col lg={2}>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAdd}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-2"
                        >
                          {" "}
                          <i
                            className="fas fa-plus"
                            style={{ fontSize: "18px" }}
                          ></i>{" "}
                         Assign Asset
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Asset</th>
                          <th>Employee</th>
                          <th>Serial Number</th>
                          <th>Asset Type</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Mac Mini</td>
                          <td>John Doe</td>
                          <td>LGJFULPDYY</td>
                          <td>Computer</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Repair
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Dell XPS 13 OLED</td>
                          <td>James Smith</td>
                          <td>LS7NNQRZMF</td>
                          <td>Computer</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              In Use
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Logitech H111 Stereo Headset</td>
                          <td>Lucy Parker</td>
                          <td>HUH926YAXH</td>
                          <td>Headset</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              In Use
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Logitech G502 Mouse</td>
                          <td>Jabari Parker</td>
                          <td>U6XLTP6DQF</td>
                          <td>Mouse</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              In Use
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* VIEW MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
            <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Asset
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Employee name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Employee Name"
                        />
                      </div>
                    </Row>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Item Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Item Name"
                        />
                      </div>
                    </Row>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </label>
                      <div className="col-md-10">    
                          <select className="form-control">
                              <option>Laptop</option>
                              <option>Desktop</option>
                              <option>Peripherals</option>
                              <option>Printer</option>
                              <option>Mobile Phone</option>
                            </select>
                      </div>
                    </Row>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Serial Number
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Asset Serial"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Purchase Date
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          placeholder="Enter Date"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Purchase Price
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Enter Price"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-2 col-form-label">Description</label>
                      <div className="col-md-10">
                        <textarea
                          id="txtAddress2Billing"
                          name="txtAddress2Billing"
                          rows="4"
                          className="form-control"
                        />
                      </div>
                    </Row>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success" onClick={toggleAdd}>
                          Save
                        </button>
                        <button type="button" className="btn btn-danger" onClick={toggleAdd}>
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Leave
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asset
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Leave Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asset Head
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Number of Days"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Select Status"
                        />
                      </div>
                    </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssetAssignmentList
