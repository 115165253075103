import React, { useState, Component } from "react"
import Select from "react-select"

import {
  Row,
  Col,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Pagination,
  PaginationItem,
  PaginationLink,
  ButtonDropdown,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardImg,
} from "reactstrap"

import styled from "styled-components"

import logoSm from "../../assets/images/logo-sm.png"
import img3 from "../../assets/images/forms/failed.PNG"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import user2 from "../../assets/images/users/user-2.jpg"
import user3 from "../../assets/images/users/user-3.jpg"
import user4 from "../../assets/images/users/user-4.jpg"
import { Link } from "react-router-dom"
import { padEnd } from "lodash"

const PerformanceAppraisal = () => {
  const [modal_center, setmodal_center] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const [drp_secondary, setDrp_secondary] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const tog_center = () => {
    setmodal_center(!modal_center)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  // document.title = "Form | Failed to Login or Logout"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Login or Logout"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <Row className="mb-3">
                    <h2>Failed to Login or Logout Form</h2>
                  </Row>

                  {/* PRINT MODAL */}
                  <Modal
                    isOpen={modalEdit}
                    toggle={toggleEdit}
                    centered
                    size="xl"
                    className="modal-fullscreen"
                  >
                    <ModalHeader
                      className="mt-0"
                      toggle={toggleEdit}
                    ></ModalHeader>
                    <ModalBody className="m-2">
                      <Row>
                        <div className="col-12">
                          <Card>
                            <CardBody>
                              <Row className="text-center">
                                <Col md={12}>
                                  <img
                                    className="img-thumbnail"
                                    alt="ByteFlare"
                                    width="900"
                                    src={img3}
                                  />
                                  <hr />
                                  <div className="d-print-none">
                                    <div className="float-end">
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          window.print()
                                        }}
                                        className="btn btn-success waves-effect waves-light"
                                      >
                                        <i
                                          className="typcn typcn-printer"
                                          style={{ fontSize: "35px" }}
                                        ></i>
                                      </Link>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </div>
                      </Row>
                    </ModalBody>

                    {/* <ModalFooter>
                      <Button color="primary" onClick={toggleEdit}>
                        Save changes
                      </Button>
                      <Button color="secondary" onClick={toggleEdit}>
                        Close
                      </Button>{" "}
                    </ModalFooter> */}
                  </Modal>

                  <hr />
                  <div className="table-responsive">
                    <table className="table table-hover table-centered table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Employee</th>
                          <th scope="col">Designation</th>
                          <th scope="col">Deparment</th>
                          <th scope="col">Requested Date</th>
                          <th scope="col">Status</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>IOS Developer</td>
                          <td>Front End</td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>Web Designer</td>
                          <td>Back End</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>IOS Developer</td>
                          <td>Front End</td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">4</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>Web Designer</td>
                          <td>Back End</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">5</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>IOS Developer</td>
                          <td>Front End</td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">6</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>Web Designer</td>
                          <td>Back End</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">7</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>IOS Developer</td>
                          <td>Front End</td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">8</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>Web Designer</td>
                          <td>Back End</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">9</th>
                          <td>
                            <div>
                              <img
                                src={user2}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Aljon Alano
                            </div>
                          </td>
                          <td>IOS Developer</td>
                          <td>Front End</td>
                          <td>1/16/2023</td>
                          <td>
                            <span
                              className="badge bg-success"
                              style={{ width: "60px" }}
                            >
                              Active
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">10</th>
                          <td>
                            <div>
                              <img
                                src={user3}
                                alt=""
                                className="avatar-xs rounded-circle me-2"
                              />{" "}
                              Jay Marc
                            </div>
                          </td>
                          <td>Web Designer</td>
                          <td>Back End</td>
                          <td>8/18/2023</td>
                          <td>
                            <span
                              className="badge bg-danger"
                              style={{ width: "60px" }}
                            >
                              Inactive
                            </span>
                          </td>
                          <td>
                            <i
                              onClick={toggleEdit}
                              className="ti-printer"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <Pagination
                  aria-label="Page navigation example"
                  listClassName="justify-content-center"
                >
                  <PaginationItem disabled>
                    <PaginationLink href="#" tabIndex="-1">
                      Previous
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">1</PaginationLink>
                  </PaginationItem>
                  <PaginationItem active>
                    <PaginationLink href="#">2</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">3</PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink href="#">Next</PaginationLink>
                  </PaginationItem>
                </Pagination>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PerformanceAppraisal
