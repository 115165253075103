import React, { useState } from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"


import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import "chartist/dist/scss/chartist.scss"

import { DateFilter, Gender } from "constants/data"
import styled from "styled-components"

const FilterTxt = styled.input`
  width: 224px;
  margin-left: 5px;
`

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const LeavesList = () => {
  const [modalView, setmodalView] = useState(false)
  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleView = () => {
    setmodalView(!modalView)
    removeBodyCss()
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const [singlebtn, setSinglebtn] = useState(false)
  const [genderbtn, setGenderbtn] = useState(false)


  document.title = "Leaves | List"
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="HRIS"
            title="MAIN"
            breadcrumbItem="Leaves"
          />
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  {/* <CardTitle className="h1">Leaves 201</CardTitle> */}
                  <Row>
                    <p className="card-title-desc">
                      Kind of Leaves
                    </p>
                  </Row>
                  <hr />
                  <Row>
                    <h2>Leaves</h2>
                    <Col/>
                    <Col lg={2}>
                      <Button
                        onClick={toggleAdd}
                        size="sm"
                        color="primary"
                        className="waves-effect waves-light col-auto"
                      >
                        <i
                          className="typcn typcn-user-add-outline"
                          style={{ fontSize: "18px" }}
                        ></i>{" "}
                        Add Leave
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th>Number of Days</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Sick Leave</td>
                          <td>12</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Vacation Leave</td>
                          <td>12</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Maternity Leave</td>
                          <td>90</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                        <tr>
                          <td>Paternal Leave</td>
                          <td>7</td>
                          <th>
                            <button
                              type="button"
                              className="btn btn-success btn-sm active"
                              style={{ width: "100px" }}
                            >
                              Active
                            </button>
                          </th>
                          <th>
                            <i
                              onClick={toggleView}
                              className="typcn typcn-user-outline"
                              style={{ fontSize: "25px" }}
                            ></i>{" "}
                            <i
                              onClick={toggleEdit}
                              className="typcn typcn-edit"
                              style={{ fontSize: "25px" }}
                            ></i>
                            <i
                              onClick={toggleDelete}
                              className="typcn typcn-trash"
                              style={{ fontSize: "25px" }}
                            ></i>
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* VIEW MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalView} toggle={toggleView} size="lg">
                <ModalHeader className="mt-0" toggle={toggleView}>
                  View Leaves
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Leave Type
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Leave Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Number of Days
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter number of days"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Select Status"
                        />
                      </div>
                    </Row>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* ADD MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalAdd} toggle={toggleAdd} size="lg">
                <ModalHeader className="mt-0" toggle={toggleAdd}>
                  <i
                    className="typcn typcn-user-add-outline"
                    style={{ fontSize: "35px" }}
                  ></i>{" "}
                  Add New Leave
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Leave Type
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Leave Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Number of Days
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="number"
                          placeholder="Enter Number of Days"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Select Status"
                        />
                      </div>
                    </Row>
                      <div className="modal-footer">
                        <button type="button" className="btn btn-success">
                          Save
                        </button>
                        <button type="button" className="btn btn-danger">
                          Close
                        </button>
                      </div>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* EDIT MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <div className="my-4 text-center">
              <Modal isOpen={modalEdit} toggle={toggleEdit} size="lg">
                <ModalHeader className="mt-0" toggle={toggleEdit}>
                  Edit Leave
                </ModalHeader>
                <ModalBody>
                  <CardBody>
                    <CardTitle className="h4"></CardTitle>
                    <p className="card-title-desc"></p>
                    <CardBody>
                      <CardTitle className="h4"></CardTitle>
                      <p className="card-title-desc"></p>
                      <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Leaves
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Leave Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Leaves Head
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Number of Days"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Status
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Select Status"
                        />
                      </div>
                    </Row>
                    </CardBody>
                  </CardBody>
                </ModalBody>
              </Modal>
            </div>
          </Col>

          {/* DELETE MODAL*/}
          <Col sm={6} md={4} xl={3}>
            <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
              <ModalHeader className="mt-0" toggle={toggleDelete}>
                Are you sure want to delete?
              </ModalHeader>
              {/* <ModalBody>
                <p>Are you sure want to delete?</p>
              </ModalBody> */}
              <div className="modal-footer">
                <button type="button" className="btn btn-danger">
                  Delete
                </button>
                <button type="button" className="btn btn-secondary">
                  Close
                </button>
              </div>
            </Modal>
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LeavesList
