import classNames from "classnames"
import Breadcrumb from "components/Common/Breadcrumb"
import React, { useState } from "react"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap"

const Adjustments = () => {
  const [customActiveTab, setCustomActiveTab] = useState("1")

  const toggleCustom = tab => {
    if (customActiveTab !== tab) setCustomActiveTab(tab)
  }

  const [modalDelete, setmodalDelete] = useState(false)
  const [modalAdd, setmodalAdd] = useState(false)
  const [modalEdit, setmodalEdit] = useState(false)

  const [modalDeleteOvertime, setmodalDeleteOvertime] = useState(false)
  const [modalAddOvertime, setmodalAddOvertime] = useState(false)
  const [modalEditOvertime, setmodalEditOvertime] = useState(false)

  const [modalDeleteDeduction, setmodalDeleteDeduction] = useState(false)
  const [modalAddDeduction, setmodalAddDeduction] = useState(false)
  const [modalEditDeduction, setmodalEditDeduction] = useState(false)

  const removeBodyCss = () => {
    document.body.classList.add("no_padding")
  }

  const toggleAdd = () => {
    setmodalAdd(!modalAdd)
    removeBodyCss()
  }

  const toggleEdit = () => {
    setmodalEdit(!modalEdit)
    removeBodyCss()
  }

  const toggleDelete = () => {
    setmodalDelete(!modalDelete)
    removeBodyCss()
  }

  const toggleAddOvertime = () => {
    setmodalAddOvertime(!modalAddOvertime)
    removeBodyCss()
  }

  const toggleEditOvertime = () => {
    setmodalEditOvertime(!modalEditOvertime)
    removeBodyCss()
  }

  const toggleDeleteOvertime = () => {
    setmodalDeleteOvertime(!modalDeleteOvertime)
    removeBodyCss()
  }

  const toggleAddDeduction = () => {
    setmodalAddDeduction(!modalAddDeduction)
    removeBodyCss()
  }

  const toggleEditDeduction = () => {
    setmodalEditDeduction(!modalEditDeduction)
    removeBodyCss()
  }

  const toggleDeleteDeduction = () => {
    setmodalDeleteDeduction(!modalDeleteDeduction)
    removeBodyCss()
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumb
            maintitle="HRIS"
            title="Payroll"
            breadcrumbItem="Adjustments"
          />
          <Card>
            <CardBody>
              <CardTitle className="h4">Payroll Adjustments</CardTitle>
              <Nav tabs className="nav-tabs-custom">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1")
                    }}
                  >
                    <span className="d-none d-sm-block">Additions</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2")
                    }}
                  >
                    <span className="d-none d-sm-block">Overtime</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classNames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3")
                    }}
                  >
                    <span className="d-none d-sm-block">Deductions</span>
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="p-3 text-muted"
              >
                <TabPane tabId="1">
                  <Row>
                    <Col lg={12}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAdd}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-1"
                        >
                          {" "}
                          <i
                            className="fas fa-plus"
                            style={{ fontSize: "14px" }}
                          ></i>{" "}
                          Add Addition
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <div className="table-responsive mt-4">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Category</th>
                                <th scope="col">Default/Unit Amount</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Leave balance amount</th>
                                <td>Monthly remuneration</td>
                                <td>$5</td>
                                <th>
                                  <i
                                    onClick={toggleEdit}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDelete}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                              <tr>
                                <th scope="row">Arrears of salary</th>
                                <td>Additional remuneration</td>
                                <td>$8</td>
                                <th>
                                  <i
                                    onClick={toggleEdit}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDelete}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                              <tr>
                                <th scope="row">Gratuity</th>
                                <td>Monthly remuneration</td>
                                <td>$20</td>
                                <th>
                                  <i
                                    onClick={toggleEdit}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDelete}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col lg={12}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAddOvertime}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-1"
                        >
                          {" "}
                          <i
                            className="fas fa-plus"
                            style={{ fontSize: "14px" }}
                          ></i>{" "}
                          Add Overtime
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <div className="table-responsive mt-4">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Rate</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Normal day OT 1.5x</th>
                                <td>Hourly 1.5</td>
                                <th>
                                  <i
                                    onClick={toggleEditOvertime}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDeleteOvertime}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                              <tr>
                                <th scope="row">Public holiday OT 3.0x</th>
                                <td>Hourly 3</td>
                                <th>
                                  <i
                                    onClick={toggleEditOvertime}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDeleteOvertime}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                              <tr>
                                <th scope="row">Rest day OT 2.0x</th>
                                <td>Hourly 2</td>
                                <th>
                                  <i
                                    onClick={toggleEditOvertime}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDeleteOvertime}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col lg={12}>
                      <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <Button
                          onClick={toggleAddDeduction}
                          size="md"
                          color="primary"
                          // outline
                          className="waves-effect waves-light mt-1"
                        >
                          {" "}
                          <i
                            className="fas fa-plus"
                            style={{ fontSize: "14px" }}
                          ></i>{" "}
                          Add Deduction
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <CardText className="mb-0">
                        <div className="table-responsive mt-4">
                          <table className="table table-hover table-centered table-nowrap mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Default/Unit Amount</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th scope="row">Absent amount</th>
                                <td>$12</td>
                                <th>
                                  <i
                                    onClick={toggleEditDeduction}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDeleteDeduction}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                              <tr>
                                <th scope="row">Advance</th>
                                <td>$7</td>
                                <th>
                                  <i
                                    onClick={toggleEditDeduction}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDeleteDeduction}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                              <tr>
                                <th scope="row">Unpaid leave</th>
                                <td>$3</td>
                                <th>
                                  <i
                                    onClick={toggleEditDeduction}
                                    className="typcn typcn-edit"
                                    style={{ fontSize: "25px" }}
                                  ></i>{" "}
                                  <i
                                    onClick={toggleDeleteDeduction}
                                    className="typcn typcn-trash"
                                    style={{ fontSize: "25px" }}
                                  ></i>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CardText>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          {/* ADDITIONS */}
          <div>
            {/* ADD MODAL*/}
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="my-4 text-center">
                <Modal isOpen={modalAdd} toggle={toggleAdd} centered size="lg">
                  <ModalHeader className="mt-0" toggle={toggleAdd}>
                    <i className="fas fa-plus" style={{ fontSize: "17px" }}></i>{" "}
                    Add Addition
                  </ModalHeader>
                  {/* ADD MODAL*/}
                  <ModalBody>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Monthly remuneration</option>
                          <option value="VI">Additional remuneration</option>
                        </Input>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Unit Amount
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Unit Amount"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asignee
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Select All</option>
                          <option value="VI">John Doe</option>
                          <option value="VI">Richard Miles</option>
                        </Input>
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
            {/* EDIT MODAL*/}
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="my-4 text-center">
                <Modal
                  isOpen={modalEdit}
                  toggle={toggleEdit}
                  centered
                  size="lg"
                >
                  <ModalHeader className="mt-0" toggle={toggleEdit}>
                    {/* <i className="fas fa-plus" style={{ fontSize: "17px" }}></i>{" "} */}
                    Edit Addition
                  </ModalHeader>
                  {/* EDIT MODAL*/}
                  <ModalBody>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Category
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Monthly remuneration</option>
                          <option value="VI">Additional remuneration</option>
                        </Input>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Unit Amount
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Unit Amount"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asignee
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Select All</option>
                          <option value="VI">John Doe</option>
                          <option value="VI">Richard Miles</option>
                        </Input>
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
            {/* DELETE MODAL*/}
            <Col sm={6} md={4} xl={3}>
              <Modal isOpen={modalDelete} toggle={toggleDelete} centered>
                <ModalHeader className="mt-0" toggle={toggleDelete}>
                  Are you sure want to Delete Addition?
                </ModalHeader>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger">
                    Delete
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Close
                  </button>
                </div>
              </Modal>
            </Col>
          </div>
          {/* OVERTIME */}
          <div>
            {/* ADD MODAL*/}
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="my-4 text-center">
                <Modal
                  isOpen={modalAddOvertime}
                  toggle={toggleAddOvertime}
                  centered
                  size="lg"
                >
                  <ModalHeader className="mt-0" toggle={toggleAddOvertime}>
                    <i className="fas fa-plus" style={{ fontSize: "17px" }}></i>{" "}
                    Add Overtime
                  </ModalHeader>
                  {/* ADD MODAL*/}
                  <ModalBody>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rate Type
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Daily Rate</option>
                          <option value="VI">Hourly Rate</option>
                        </Input>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rate
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Rate"
                        />
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
            {/* EDIT MODAL*/}
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="my-4 text-center">
                <Modal
                  isOpen={modalEditOvertime}
                  toggle={toggleEditOvertime}
                  centered
                  size="lg"
                >
                  <ModalHeader className="mt-0" toggle={toggleEditOvertime}>
                    {/* <i className="fas fa-plus" style={{ fontSize: "17px" }}></i>{" "} */}
                    Edit Overtime
                  </ModalHeader>
                  {/* EDIT MODAL*/}
                  <ModalBody>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rate Type
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Daily Rate</option>
                          <option value="VI">Hourly Rate</option>
                        </Input>
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Rate
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Rate"
                        />
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
            {/* DELETE MODAL*/}
            <Col sm={6} md={4} xl={3}>
              <Modal
                isOpen={modalDeleteOvertime}
                toggle={toggleDeleteOvertime}
                centered
              >
                <ModalHeader className="mt-0" toggle={toggleDeleteOvertime}>
                  Are you sure want to Delete Overtime?
                </ModalHeader>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger">
                    Delete
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Close
                  </button>
                </div>
              </Modal>
            </Col>
          </div>
          {/* DEDUCTIONS */}
          <div>
            {/* ADD MODAL*/}
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="my-4 text-center">
                <Modal
                  isOpen={modalAddDeduction}
                  toggle={toggleAddDeduction}
                  centered
                  size="lg"
                >
                  <ModalHeader className="mt-0" toggle={toggleAddDeduction}>
                    <i className="fas fa-plus" style={{ fontSize: "17px" }}></i>{" "}
                    Add Deduction
                  </ModalHeader>
                  {/* ADD MODAL*/}
                  <ModalBody>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Unit calculation
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Unit calculation"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asignee
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Select All</option>
                          <option value="VI">John Doe</option>
                          <option value="VI">Aljon Alano</option>
                        </Input>
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
            {/* EDIT MODAL*/}
            <Col sm={12} md={12} xl={12} lg={12}>
              <div className="my-4 text-center">
                <Modal
                  isOpen={modalEditDeduction}
                  toggle={toggleEditDeduction}
                  centered
                  size="lg"
                >
                  <ModalHeader className="mt-0" toggle={toggleEditDeduction}>
                    {/* <i className="fas fa-plus" style={{ fontSize: "17px" }}></i>{" "} */}
                    Edit Deduction
                  </ModalHeader>
                  {/* EDIT MODAL*/}
                  <ModalBody>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Name
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Name"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Unit calculation
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Unit calculation"
                        />
                      </div>
                    </Row>
                    <Row className="mb-3 d-flex align-items-center justify-content-center">
                      <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label"
                      >
                        Asignee
                      </label>
                      <div className="col-md-10">
                        <Input
                          type="select"
                          id="ddlCreditCardType"
                          name="ddlCreditCardType"
                          className="form-select"
                        >
                          <option value="">-- Please Select --</option>
                          <option value="AE">Select All</option>
                          <option value="VI">John Doe</option>
                          <option value="VI">Aljon Alano</option>
                        </Input>
                      </div>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </Col>
            {/* DELETE MODAL*/}
            <Col sm={6} md={4} xl={3}>
              <Modal
                isOpen={modalDeleteDeduction}
                toggle={toggleDeleteDeduction}
                centered
              >
                <ModalHeader className="mt-0" toggle={toggleDeleteDeduction}>
                  Are you sure want to Delete Deduction?
                </ModalHeader>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger">
                    Delete
                  </button>
                  <button type="button" className="btn btn-secondary">
                    Close
                  </button>
                </div>
              </Modal>
            </Col>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Adjustments
