import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

class Line extends Component {
  getOption = () => {
    return {
      tooltip: {
        trigger: "axis",
      },
      toolbox: {
        show: false,
        feature: {
          saveAsImage: {},
        },
      },
      grid: {
        zlevel: 0,
        x: 50,
        x2: 50,
        y: 30,
        y2: 30,
        borderWidth: 0,
        backgroundColor: "rgba(0,0,0,0)",
        borderColor: "rgba(0,0,0,0)",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          crossStyle: {
            color: "#999",
          },
        },
      },

      xAxis: {
        type: "category",
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      yAxis: {
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
      series: [
        {
          name: "Gross Salary",
          data: [
            128200 - 30000,
            105492 - 30000,
            41901 - 30000,
            130934 - 30000,
            120390 - 30000,
            140330 - 30000,
            100320 - 30000,
            80320 - 30000,
            136420 - 30000,
          ],
          type: "bar",
        },
        {
          name: "Net Salary",
          data: [
            128200, 105492, 41901, 130934, 120390, 140330, 100320, 80320,
            136420,
          ],
          type: "line",
        },
        {
          name: "Taxes",
          data: [
            128200 - 41500,
            105492 - 41500,
            41901 - 41500,
            130934 - 41500,
            120390 - 41500,
            140330 - 41500,
            100320 - 41500,
            80320 - 41500,
            136420 - 41500,
          ],
          type: "bar",
        },
      ],
      color: ["#44c5e2", "#3c4ccf", "#8382de"],
      legend: {
        data: ["Gross Salary", "Taxes", "Net Salary"],
        textStyle: {
          color: ["#44c5e2", "#3c4ccf", "#8382de"],
        },
      },
    }
  }
  render() {
    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "450px" }} option={this.getOption()} />
      </React.Fragment>
    )
  }
}
export default Line
