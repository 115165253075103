export const DateFilter = {
  YEAR: "Year",
  MONTH: "Month",
  WEEK: "Week",
}

export const selectEmployee = [
  {
    label: "Accounting Department",
    options: [
      { label: "Jose", value: "Jose" },
      { label: "Christian", value: "Christian" },
      { label: "Andress", value: "Andress" },
      { label: "Harlan", value: "Harlan" }
    ]
  },
  {
    label: "IT Department",
    options: [
      { label: "Jay", value: "Jay" },
      { label: "Aljon", value: "Aljon" },
      { label: "Kalil", value: "Kalil" },
      { label: "Brent", value: "Brent" }
    ]
  }
];

export const PayrollOverAllStats = [
  {
    title: "WEB DEVELOPER",
    total_amount: "$18960",
    increase: "12",
    decrease: "",
  },
  {
    title: "APP DEVELOPER",
    total_amount: "$11783",
    increase: "",
    decrease: "28",
  },
  {
    title: "DESIGNER",
    total_amount: "$2254",
    increase: "5.7",
    decrease: "",
  },
  {
    title: "MARKETING",
    total_amount: "$18960",
    increase: "84",
    decrease: "",
  },
]

export const Gender = {
  MALE: "Male",
  FEMALE: "Female",
  OTHER: "Other",
}

export const ShiftType = {
  DayShift: "Day Shift",
  MidShift: "Mid Shift",
  NightShift: "Night Shift",
  Custom: "Custom Shift",
}

export const EmployeeSummary = [
  {
    _id: "64142d2d435fa74de2c63c22",
    index: 0,
    guid: "d18b7e12-2e43-460d-9961-1df95ce2b2f5",
    name: "Bentley Chaney",
    role: "Backend-Developer",
    email: "bentleychaney@byteflare.com",
    gross: 216761.42,
    taxes: 421.96,
    performance: "Good",
    pay_status: 0,
    photo: "user2",
  },
  {
    _id: "64142d2d7724dd51d53d9a74",
    index: 1,
    guid: "93f2edac-bdeb-4130-922e-8445f9611e45",
    name: "Felicia Puckett",
    role: "HR Manager",
    email: "feliciapuckett@byteflare.com",
    gross: 194344.97,
    taxes: 759.97,
    performance: "Good",
    pay_status: 2,
    photo: "user5",
  },
  {
    _id: "64142d2d62d41bc601a7b797",
    index: 2,
    guid: "7af9598d-a457-40eb-aa0f-386cca1ebed2",
    name: "Fowler Odom",
    role: "Software Developer",
    email: "fowlerodom@byteflare.com",
    gross: 258099.45,
    taxes: 761.72,
    performance: "Good",
    pay_status: 0,
    photo: "user4",
  },
  {
    _id: "64142d2d5124ca5571fabbbf",
    index: 3,
    guid: "f24bafb1-33ce-415e-8963-fb89ee987cb5",
    name: "Patterson Macdonald",
    role: "HR Manager",
    email: "pattersonmacdonald@byteflare.com",
    gross: 36759.71,
    taxes: 615.43,
    performance: "Good",
    pay_status: 2,
    photo: "user4",
  },
  {
    _id: "64142d2d243d029db438dd1d",
    index: 4,
    guid: "7752bc26-59d6-4f58-a7c9-03fcb3ca6205",
    name: "Wood Hayden",
    role: "UX Designer",
    email: "woodhayden@byteflare.com",
    gross: 127536.91,
    taxes: 712.8,
    performance: "Poor",
    pay_status: 0,
    photo: "user5",
  },
  {
    _id: "64142d2d7728bf1b9d7a675e",
    index: 5,
    guid: "3dbbd167-6d7e-46ac-a83a-7cd6a8a4c915",
    name: "Leon Malone",
    role: "UX Designer",
    email: "leonmalone@byteflare.com",
    gross: 292303.68,
    taxes: 506,
    performance: "Poor",
    pay_status: 0,
    photo: "user2",
  },
  {
    _id: "64142d2d9ecd4f0c4d508f29",
    index: 6,
    guid: "8d011314-7d59-425c-a995-58f660344bcf",
    name: "Cunningham Skinner",
    role: "Full-Stack Developer",
    email: "cunninghamskinner@byteflare.com",
    gross: 42844.32,
    taxes: 511.42,
    performance: "Poor",
    pay_status: 1,
    photo: "user6",
  },
  {
    _id: "64142d2dcd53a8eed74f0709",
    index: 7,
    guid: "609d582d-60e9-4bbb-9e30-4e9d04578a39",
    name: "Chambers Lawson",
    role: "Software Developer",
    email: "chamberslawson@byteflare.com",
    gross: 149318.53,
    taxes: 431.38,
    performance: "Good",
    pay_status: 0,
    photo: "user2",
  },
  {
    _id: "64142d2d52b3bdab117de1e5",
    index: 8,
    guid: "2ea3e07d-5bff-4e5b-bc0a-6004bef3acd9",
    name: "Dena Christensen",
    role: "HR Manager",
    email: "denachristensen@byteflare.com",
    gross: 227993.63,
    taxes: 815,
    performance: "Good",
    pay_status: 2,
    photo: "user4",
  },
  {
    _id: "64142d2d38d69c54ea68ff62",
    index: 9,
    guid: "4b0bacf5-936e-4b33-8245-7a652d88b9a4",
    name: "Boyer Beck",
    role: "Full-Stack Developer",
    email: "boyerbeck@byteflare.com",
    gross: 27887.81,
    taxes: 790.7,
    performance: "Poor",
    pay_status: 2,
    photo: "user3",
  },
  {
    _id: "64142d2dbf254c78413eb296",
    index: 10,
    guid: "2d11a62e-b8c7-4c2f-a240-97979d531f7c",
    name: "Kara Page",
    role: "Project Manager",
    email: "karapage@byteflare.com",
    gross: 91536.55,
    taxes: 825.02,
    performance: "Moderate",
    pay_status: 1,
    photo: "user4",
  },
  {
    _id: "64142d2d9c91976ceffe4041",
    index: 11,
    guid: "7c23ee7c-c61c-4217-b57c-78d5703c98b8",
    name: "Regina Avery",
    role: "IT Head",
    email: "reginaavery@byteflare.com",
    gross: 269950.5,
    taxes: 443.63,
    performance: "Good",
    pay_status: 1,
    photo: "user5",
  },
  {
    _id: "64142d2d4cecde660062339b",
    index: 12,
    guid: "302bd591-66a4-46ff-8093-3fcb59d4088d",
    name: "Mann Wiggins",
    role: "HR Manager",
    email: "mannwiggins@byteflare.com",
    gross: 269835.25,
    taxes: 397.08,
    performance: "Moderate",
    pay_status: 1,
    photo: "user6",
  },
  {
    _id: "64142d2de97258b4e5aebca1",
    index: 13,
    guid: "abba76c6-11d0-47a4-b219-da7aee78227d",
    name: "Gabrielle Weaver",
    role: "UX Designer",
    email: "gabrielleweaver@byteflare.com",
    gross: 63013.14,
    taxes: 722.05,
    performance: "Good",
    pay_status: 2,
    photo: "user2",
  },
  {
    _id: "64142d2da060543ad800ee3f",
    index: 14,
    guid: "ff291e05-1119-4a80-8197-6cfe4c795234",
    name: "Karin Summers",
    role: "Project Manager",
    email: "karinsummers@byteflare.com",
    gross: 334795.59,
    taxes: 291.99,
    performance: "Good",
    pay_status: 1,
    photo: "user4",
  },
  {
    _id: "64142d2df844b14fad3bd060",
    index: 15,
    guid: "bc685929-972e-48f4-a69a-7e4f76ac7732",
    name: "Acevedo Vaughan",
    role: "Project Manager",
    email: "acevedovaughan@byteflare.com",
    gross: 141762.63,
    taxes: 451.8,
    performance: "Good",
    pay_status: 1,
    photo: "user3",
  },
  {
    _id: "64142d2d0a18e2d78e49d3cd",
    index: 16,
    guid: "64ced28f-3443-4703-a569-3515be6c12b4",
    name: "Reese Duffy",
    role: "Software Developer",
    email: "reeseduffy@byteflare.com",
    gross: 196230.51,
    taxes: 109.28,
    performance: "Poor",
    pay_status: 1,
    photo: "user5",
  },
  {
    _id: "64142d2d67d8ccdc3a11443d",
    index: 17,
    guid: "cb61650f-8112-4a72-b822-8e1a3f17bbcf",
    name: "Angel Owen",
    role: "Software Developer",
    email: "angelowen@byteflare.com",
    gross: 341949.22,
    taxes: 814.22,
    performance: "Good",
    pay_status: 1,
    photo: "user6",
  },
  {
    _id: "64142d2df5b1b5ab4a85df0a",
    index: 18,
    guid: "f5010303-6d15-499d-853f-ff2601aa84cb",
    name: "Bass Burns",
    role: "IT Head",
    email: "bassburns@byteflare.com",
    gross: 315427.94,
    taxes: 838.64,
    performance: "Moderate",
    pay_status: 2,
    photo: "user3",
  },
  {
    _id: "64142d2de10dad9841d258b8",
    index: 19,
    guid: "e61ef0b9-1527-4f51-b9b2-e4f909b7df45",
    name: "Mullen Houston",
    role: "Software Developer",
    email: "mullenhouston@byteflare.com",
    gross: 294170.83,
    taxes: 639.74,
    performance: "Good",
    pay_status: 1,
    photo: "user2",
  },
  {
    _id: "64142d2dbf030ba7441a93e5",
    index: 20,
    guid: "52f82c7f-55b3-4703-a23f-070d24cd0013",
    name: "Debora Callahan",
    role: "UX Designer",
    email: "deboracallahan@byteflare.com",
    gross: 346459.36,
    taxes: 563.16,
    performance: "Moderate",
    pay_status: 1,
    photo: "user2",
  },
  {
    _id: "64142d2d3e87e40ca7153459",
    index: 21,
    guid: "2d68bf59-77f5-44cb-aa4d-406162e690f3",
    name: "Lupe Berger",
    role: "UX Designer",
    email: "lupeberger@byteflare.com",
    gross: 35889.54,
    taxes: 299.36,
    performance: "Good",
    pay_status: 1,
    photo: "user5",
  },
  {
    _id: "64142d2d9aaff196a533b767",
    index: 22,
    guid: "bce7de4d-4605-4147-a393-5912d349b2bf",
    name: "Shelley Brewer",
    role: "Backend-Developer",
    email: "shelleybrewer@byteflare.com",
    gross: 250276.24,
    taxes: 512.95,
    performance: "Poor",
    pay_status: 1,
    photo: "user4",
  },
  {
    _id: "64142d2df98825d466a89d46",
    index: 23,
    guid: "eb4ad6b1-a265-4b87-8f61-bd3f1b92de09",
    name: "Williamson Mayer",
    role: "Backend-Developer",
    email: "williamsonmayer@byteflare.com",
    gross: 266646.93,
    taxes: 409.86,
    performance: "Moderate",
    pay_status: 2,
    photo: "user5",
  },
]

export const EmployeeSummaryTimeOff = [
  {
    _id: "64144efcd82a906df93c2eee",
    index: 0,
    guid: "4f3e7a2c-ba0a-4acd-92f5-22f43cfe5da3",
    name: "Norman Medina",
    role: "Front-End Developer",
    email: "normanmedina@byteflare.com",
    gross: 148452.18,
    taxes: 600.77,
    performance: "Moderate",
    pay_status: 0,
    photo: "user6",
    time_off: 1,
    off_type: "Sick leave",
    off_date: "26 - 28 Jul 2023",
  },
  {
    _id: "64144efc3c199e90a3a5e825",
    index: 1,
    guid: "32289d04-aed8-483b-80f9-617861844fe2",
    name: "Wynn Ramirez",
    role: "Front-End Developer",
    email: "wynnramirez@byteflare.com",
    gross: 38153.83,
    taxes: 321.92,
    performance: "Good",
    pay_status: 0,
    photo: "user2",
    time_off: 1,
    off_type: "Family Trip",
    off_date: "1 - 7 Aug 2023",
  },
  {
    _id: "64144efc42e013153d73de5c",
    index: 2,
    guid: "4f376051-fa9d-47e1-afe0-36f00ffcb7f7",
    name: "Clements Grant",
    role: "Software Developer",
    email: "clementsgrant@byteflare.com",
    gross: 276289.8,
    taxes: 760.01,
    performance: "Moderate",
    pay_status: 1,
    photo: "user5",
    time_off: 1,
    off_type: "Personal Issue",
    off_date: "6 - 10 Aug 2023",
  },
]

export const BENEFITS = [
  {
    type: "SSS",
    sub_type: "Regular",
    percentage: false,
    value: null,
    min: 2250,
    max: 2749,
    employee: 80,
    employer: 160,
    status: 1,
    contribution: true,
  },
  {
    type: "SSS",
    sub_type: "Regular",
    percentage: false,
    value: null,
    min: 10250,
    max: 10749,
    employee: 840,
    employer: 420,
    status: 1,
    contribution: true,
  },
  {
    type: "SSS",
    sub_type: "Regular",
    percentage: false,
    value: null,
    min: 19750,
    max: null,
    employee: 800,
    employer: 1600,
    status: 1,
    contribution: true,
  },
  {
    type: "SSS",
    sub_type: "Maternity Benefit",
    percentage: true,
    value: 10,
    employee: 0,
    employer: 0,
    status: 1,
    contribution: false,
  },
  {
    type: "SSS",
    sub_type: "Sickness Benefit",
    percentage: true,
    value: 10,
    employee: 0,
    employer: 0,
    status: 1,
    contribution: false,
  },
  {
    type: "PHILHEALTH",
    sub_type: "Regular",
    percentage: false,
    value: null,
    min: 0,
    max: 10000,
    employee: 137.5,
    employer: 137.5,
    status: 1,
    contribution: true,
  },
  {
    type: "PHILHEALTH",
    sub_type: "Regular",
    percentage: false,
    value: null,
    min: 10000,
    max: 39999,
    employee: 549.99,
    employer: 549.99,
    status: 1,
    contribution: true,
  },
  {
    type: "PHILHEALTH",
    sub_type: "Regular",
    percentage: false,
    value: null,
    min: 40000,
    max: 0,
    employee: 550,
    employer: 550,
    status: 1,
    contribution: true,
  },
  {
    type: "PAGIBIG",
    sub_type: "Regular",
    percentage: true,
    value: 1,
    min: 0,
    max: 1500,
    employee: 0,
    employer: 0,
    status: 1,
    contribution: false,
  },
  {
    type: "PAGIBIG",
    sub_type: "Regular",
    percentage: true,
    value: 2,
    min: 1500,
    max: 0,
    employee: 0,
    employer: 0,
    status: 1,
    contribution: false,
  },
]
